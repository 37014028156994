import React from 'react'
import { useRef, useState, useEffect, useContext } from 'react';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import AdminPool from '../AdminPool';
import { AiFillLock } from 'react-icons/ai';
import { useNavigate, Link } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';

const SignIn = () => {
    let navigate = useNavigate();
    const {setAuth} = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    
    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, password])

    const handleSubmit = (event) => {
        event.preventDefault();

        const user = new CognitoUser({
            Username: email,
            Pool: AdminPool
        });

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        // console.log(authDetails)
        // console.log(user)

        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                AdminPool.getCurrentUser().getSession((err,session) =>{
                    if (err) {
                        console.log(err)
                    } else {
                        // console.log(session)
                        sessionStorage.setItem('faindsAdminToken', `${session.getIdToken().getJwtToken()}`)
                    }})
                    navigate("/dashboard");
            },
            onFailure: (err) => {
                if (!err?.response) {
                    setErrMsg('No Server Response');
                } else if (err.response?.status === 400) {
                    setErrMsg('Missing Email or Password')
                } else if (err.response?.status === 401) {
                    setErrMsg('Unauthorized')
                } else {
                    setErrMsg('Login Failed')
                }
                errRef.current.focus();                
            }
        });
    }

  return (
    <div className="min-h-full flex items-center justify-center px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
            <div>
                <h1 className='text-center my-14 w-auto mx-auto text-5xl font-bold text-white'><Link to="/">f<span className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500'>AI</span>nds</Link><span className='text-sm text-white'>admin</span></h1>
            </div>
            <form onSubmit={handleSubmit} className="mt-8 space-y-6" action="#" method="POST">
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                <div>
                    <label htmlFor="email-address" className="sr-only">
                    Email address
                    </label>
                    <input
                    id="email-address"
                    type="email"
                    autoComplete="off"
                    ref={userRef}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                    placeholder='Email'
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-[#03a4aa] focus:border-[#03a4aa] focus:z-10 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="password" className="sr-only">
                    Password
                    </label>
                    <input
                    id="password"
                    type="password"
                    onChange={(event) => setPassword(event.target.value)}
                    required
                    placeholder='Password'
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-[#03a4aa] focus:border-[#03a4aa] focus:z-10 sm:text-sm"
                    />
                </div>
                </div>

                <div>
                <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 text-sm font-medium rounded-md text-black bg-gradient-to-r from-teal-500 to-cyan-500 hover:bg-[#1b6099]"
                >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <AiFillLock className="h-5 w-5 text-black" aria-hidden="true" />
                    </span>
                    Sign in
                </button>
                </div>
                <p ref={errRef} className={errMsg ? "flex justify-center font-medium text-red-400" : "absolute left-9999:"} aria-live="assertive">
                    {errMsg}
                </p>
            </form>
            </div>
        </div>
  )
}

export default SignIn;
