import { useLocation } from 'react-router-dom';
import { React, useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import Profile from '../components/Profile';
import Footer from '../components/Footer';
import ResultHeader from '../components/ResultHeader';
import ResultFilter from '../components/ResultFilter';
import Toolbar from '../components/Toolbar';
import NavbarTool from '../components/NavbarTool';


function AppResultPage() {
    const location = useLocation();
    const data = location.state.result;
    console.log('DATA RESULT: ',data)

    const [currentPage, setCurrentPage] = useState(0)
    const [currentProfiles, setCurrentProfiles] = useState([])
    const [pageCount, setPageCount] = useState(1)
    const [profileOffset, setProfileOffset] = useState(0)
    const profilesPerPage = 10

    useEffect(() => {
        const endOffset = profileOffset + profilesPerPage;
        setCurrentProfiles(data.slice(profileOffset, endOffset));
        setPageCount(Math.ceil(data.length / profilesPerPage));
    }, [profileOffset, profilesPerPage, data]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * profilesPerPage) % data.length;
        setProfileOffset(newOffset);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        setCurrentPage(event.selected)
    }

    const handleSubmit = async (profile) => {
        localStorage.setItem('singleprofile', JSON.stringify(profile));
        window.open('/profile')
    }

    return (
    <div className='text-white bg-slate-50 dark:bg-black'>
        <NavbarTool />
        <ResultHeader result_count={data.length}/>
        {/* <ResultFilter /> */}
        <div className='sm:mx-4 mx-2'>
            <ReactPaginate 
            key="paginate1"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            forcePage={currentPage}
            containerClassName={"flex justify-between shadow bg-white dark:bg-gray-900 px-4 py-3 sm:px-6 rounded-lg max-w-[1000px] mx-auto"}
            previousLinkClassName={"inline-flex rounded-l-md border border-gray-100 dark:border-gray-800 bg-white dark:bg-gray-900 px-2 py-2 text-sm font-bold text-gray-500 hover:bg-gray-50"}
            nextLinkClassName={"inline-flex rounded-r-md border border-gray-100 dark:border-gray-800 bg-white dark:bg-gray-900 px-2 py-2 text-sm font-bold text-gray-500 hover:bg-gray-50"}
            activeClassName={"border border-[#03a4aa] bg-teal-50 text-sm font-bold text-[#03a4aa] hover:bg-gray-50"}
            pageLinkClassName={"inline-flex border border-gray-100 dark:border-gray-800 bg-white dark:bg-gray-900 px-2 sm:px-4 py-2 text-sm font-bold hover:bg-gray-50 text-gray-500"}
            breakLinkClassName={"inline-flex border border-gray-100 dark:border-gray-800 bg-white dark:bg-gray-900 px-2 sm:px-4 py-2 text-sm font-bold hover:bg-gray-50 text-gray-500"}
            />
        </div>
        <div className='flex flex-wrap mx-auto max-w-[1024px] justify-center'>
            {currentProfiles?.map(p => <Profile
                onSubmit = {handleSubmit}
                key = {Math.random()}
                id = {Math.random()}
                name = {p.name}
                location = {p.location}
                current_industry = {p.current_industry}
                distance = {p.distance}
                companys = {p?.jobs?.companys}
                career = {p.jobs.career}
                education = {p.educations}
                skills = {p.skills}
                languages = {p.languages}
                score = {p.score}
                general_insights = {p.result.general_insights}
                time_insights = {p.result.time_insights}
                company_insights = {p.result.company_insights}
                education_insights = {p.result.education_insights}
                job_insights = {p.result.job_insights}
                link = {p.link} 
                twitter = {p.twitter}
                personal_website = {p.personal_website}
                contacting = {p.contacting}           
            />)}
        </div>
        <div className='sm:mx-4 mx-2'>
            <ReactPaginate 
            key="paginate2"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            forcePage={currentPage}
            containerClassName={"flex justify-between shadow bg-white dark:bg-gray-900 px-4 py-3 sm:px-6 rounded-lg max-w-[1000px] mx-auto"}
            previousLinkClassName={"inline-flex rounded-l-md border border-gray-100 dark:border-gray-800 bg-white dark:bg-gray-900 px-2 py-2 text-sm font-bold text-gray-500 hover:bg-gray-50"}
            nextLinkClassName={"inline-flex rounded-r-md border border-gray-100 dark:border-gray-800 bg-white dark:bg-gray-900 px-2 py-2 text-sm font-bold text-gray-500 hover:bg-gray-50"}
            activeClassName={"border border-[#03a4aa] bg-teal-50 text-sm font-bold text-[#03a4aa] hover:bg-gray-50"}
            pageLinkClassName={"inline-flex border border-gray-100 dark:border-gray-800 bg-white dark:bg-gray-900 px-2 sm:px-4 py-2 text-sm font-bold hover:bg-gray-50 text-gray-500"}
            breakLinkClassName={"inline-flex border border-gray-100 dark:border-gray-800 bg-white dark:bg-gray-900 px-2 sm:px-4 py-2 text-sm font-bold hover:bg-gray-50 text-gray-500"}
            />
        </div>
        <div className='pb-10'>
            <Footer/>
        </div>
        <Toolbar />
    </div>            
    )
}

export default AppResultPage