import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Upload from '../components/Upload';
import Welcome from '../components/Welcome';
import NavbarTool from '../components/NavbarTool';
import { useTranslation } from 'react-i18next';

export default function AppUploadPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleLoading = () => {
    setLoading(!loading);
  };

  const handleSubmit = async (file) => {
    // console.log(file.type)
    if(file.type === "application/pdf") {
    const formData = new FormData();
    formData.append("file_data", file);
    // setloading(true);
          await axios({
              method: "post",
              url: "https://sc8oqenus2.execute-api.eu-central-1.amazonaws.com/test/pdf-match", //"http://127.0.0.1:8000/match",//"
              //url: 'https://fainds-backend.herokuapp.com/match',
              data: formData,
              headers: { "Content-Type": "multipart/form-data", "Authorization": localStorage.getItem('faindsToken')},
          })
          .then(res=>{
              navigate("/results", {state:res.data});
              // console.log(res.data[0])
            })
          .catch(error => {
            if (!error?.response) {
              console.log('No Server Response');
              } else if (error.request) {
                console.log(error.message)
                localStorage.removeItem('faindsToken');
                window.alert("Session expired")
                navigate("/login")
              } else {
                console.log('Unknown Error')
              }
            })
    } else {
      const formData = new FormData();
      formData.append("query", file);
      // console.log(file)
      // setloading(true);
            await axios({
                method: "post",
                url: "https://sc8oqenus2.execute-api.eu-central-1.amazonaws.com/test/match",
                data: {query:file},
                headers: { "Content-Type": "application/json", "Authorization": localStorage.getItem('faindsToken')}
            })
            .then(res => {
                localStorage.setItem('message', `${file}`)
                console.log(res)
                localStorage.setItem('filter_data', JSON.stringify({     
                  min_work_exp: res.data.work_exp,
                  max_work_exp: "",
                  highest_education: "",
                  min_company_reputation: "",
                  max_company_reputation: "",
                  min_university_reputation: "",
                  max_university_reputation: "",
                  location: res.data.location[0]?.stadt,
                  language: "",
                  net_min_work_exp: "",
                  net_max_work_exp: "",
                  min_lead_duration: "",
                  max_lead_duration: "",
                  job_title: res.data.job_title,
                  skills: res.data.skills
                }))
                navigate("/results", {state:res.data});
                // console.log('Im past the navigate')
                // localStorage.setItem('job_title', (res.data.job_title));
                // localStorage.setItem('location', (res.data.location[0].stadt));
                // localStorage.setItem('skills', (res.data.skills));
                // localStorage.setItem('min_work_exp', (res.data.work_exp));
                // localStorage.setItem('filter_data', JSON.stringify(data))
              })
            .catch(error => {
                if (!error?.response) {
                  console.log('No Server Response');
                  handleLoading();
                  } else if (error.request) {
                    // localStorage.removeItem('faindsToken');
                    // localStorage.removeItem('job_title');
                    // localStorage.removeItem('location');
                    // localStorage.removeItem('skills');
                    // localStorage.removeItem('work_exp');
                    window.alert("Session expired")
                    navigate("/login")
                    console.log(error.message)
                  } else {
                    console.log('Unknown Error')
                  }
              })
    }
  }

  return (
    <>
      {!loading ? 
        <div className='absolute flex flex-col items-center justify-center h-full w-screen bg-gray-900 opacity-70 z-[-10]'> 
          <div className="h-3 relative w-[50%] rounded-full overflow-hidden">
            <div className="w-full h-full bg-gray-200 absolute"></div>
            <div id="bar" className="h-full bg-gradient-to-r from-teal-500 to-cyan-500 absolute w-0"></div>
          </div>
        </div> : 
        <div className='absolute flex flex-col items-center justify-center h-full w-screen bg-gray-900 opacity-70 z-30'> 
          <div className="h-3 relative w-[50%] rounded-full overflow-hidden">
            <div className="w-full h-full bg-gray-200 absolute"></div>
            <div id="bar" className="delay-150 ease-in-out duration-[10000ms] h-full bg-gradient-to-r from-teal-500 to-cyan-500 absolute w-full"></div>
          </div>
          <p className='text-white text-center mt-4'>{t("upload.loadText")} </p>
        </div>
      }

      <div className='h-screen dark:bg-black bg-gray-50 flex flex-col'>
        <NavbarTool />
        <Welcome />
        <Upload onSubmit={handleSubmit} onLoading={handleLoading} />
      </div>  
        <Footer />
      
    </>
  )
}

// export default Start();
