import { Navigate } from "react-router-dom";

const Protected = ({ children }) => {

    if (!localStorage.getItem('faindsToken')) {
        return <Navigate to="/login" replace />;
    }
    return children;
  
}
  
export default Protected;