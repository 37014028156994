import React from 'react'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Matching from '../assets/matching.PNG';
import CV from '../assets/cv.PNG';
import { CheckIcon } from '@heroicons/react/solid'
import { FaAngleDoubleDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function SolutionPage() {
  let navigate = useNavigate();
  return (
    <div>
        <Navbar />
        <div className='w-full bg-white py-4'>

          <div className='dark:bg-black bg-slate-50 dark:text-slate-100 text-slate-900'>
              <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
                  <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500 text-4xl font-bold py-4'>
                  RECRUIT BETTER. NO GUESSWORK.
                  </h1>
                  <p className='md:text-2xl text-xl font-bold text-gray-500'>
                  Recruiting teams can finally focus on what matters most - people, not the process. fAInds uses AI technology to promote more efficient and human recruitment methods. Easily find and connect with top talent without spending unnecessary money and time on recruitment or advertising. fAInds gives you the edge to reach the best talent first.
                  </p>
                  <p className='mx-auto my-16'>Find out how it works
                  <FaAngleDoubleDown size={60} className='display:block mx-auto'/>
                  </p>
              </div>
          </div>

          <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 px-4'>
            <div className='flex flex-col justify-center'>
              <p className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500 font-bold sm:pt-2 pt-8'>Unbiased and objective candidate selection with AI.</p>
              <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Intelligent CV Screening </h1>
              <p className='py-2'>We gather all public information about the potential candidate and evaluate it with our technology, to give you the best matching and scoring in the searching process</p>
              <div className='flex'>
              <CheckIcon className="h-5 w-5"/>
              <p>AI candidate screening with relevant insights.</p>
              </div>
              <div className='flex'>
              <CheckIcon className="h-5 w-5"/>
              <p>Data-driven, in-depth candidate analysis.</p>
              </div>
              <div className='flex'>
              <CheckIcon className="h-5 w-5"/>
              <p>Includes all public information and a company score.</p>
              </div>
              <div className='flex'>
              <CheckIcon className="h-5 w-5"/>
              <p>Evaluates the Skillset level and working experience.</p>
              </div>
            </div>
            <img className='w-[500px] mx-auto my-4' src={CV} alt='/' />
          </div>

          <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 px-4'>
            <img className='w-[650px] mx-auto my-4' src={Matching} alt='/' />
            <div className='flex flex-col justify-center'>
              <p className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500 font-bold '>Let our talent database work for you to discover talents</p>
              <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>AI based Matching </h1>
              <p>We offer the most advanced AI-based service in recruiting that completely and efficiently cuts down the most time-consuming routine tasks.</p>
              <p className='py-2'>You simply upload a PDF of your Job listing and we provide you with a ranking of the most fitting candidates.</p>
              <p>With f<span className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500'>AI</span>nds, you and your team save valuable hours every day that you can invest where it matters most: building relationships with your candidates</p>
            </div>
          </div>
          <button 
            onClick={() => {
              navigate("/contact");
            }}
            className='bg-gradient-to-r from-teal-500 to-cyan-500 w-[200px] justify-center flex rounded-md font-medium my-6 mx-auto py-3 text-black'>Contact Us
            </button>

        </div>
        <Footer /> 
    </div>
  )
}

export default SolutionPage