import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import FreeText from '../components/FreeText';
import Welcome from '../components/Welcome';

export default function AppFreeTextPage() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false)


  const handleSubmit = async (text) => {
    const formData = new FormData();
    formData.append("job_description", text);
    console.log(text)
    setloading(true);
          await axios({
              method: "post",
              url: 'http://127.0.0.1:8000/match/text_match',
              data: formData,
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
          })
          .then(res=>{
              navigate("/results", {state:res.data});
              setloading(false);
              // console.log(res.data)
            })
          .catch(err => {
              setloading(false);
              console.log(err);
            })
  }

  return (
    <div>
      <Navbar />
      <div className='h-screen'>
        <Welcome />
        <button 
          onClick={() => {
            navigate("/upload");
          }}
          className=' bg-[#6EA8D8] hover:bg-[#1b6099] w-[300px] justify-center flex rounded-md font-medium my-1 mx-auto py-1 text-black'>Switch to PDF Upload
        </button>
        <FreeText onSubmit={handleSubmit} />
        <div className='text-white justify-center items-center max-w-[1240px] mx-auto flex flex-col'>
          <ThreeDots
          height="80" 
          width="80" 
          radius="9"
          color="#6EA8D8" 
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName="center"
          visible={loading}
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}

// export default FreeText();
