import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { RiMoonClearLine, RiSunFill } from "react-icons/ri";


const NavbarTool = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const [dark, setDark] = useState(localStorage.theme === "dark" ? true : false);
  const [login] = useState(localStorage.faindsToken ? true : false);
  // console.log(login)

  const darkMode = () => {
    setDark(!dark);
  };

  useEffect(() => {
    if (dark) {
      localStorage.theme = "dark";
      document.documentElement.classList.add("dark");
    } else {
      localStorage.theme = "light";
      document.documentElement.classList.remove("dark");
    }
  }, [dark]);

  const { t, i18n } = useTranslation();

  const onClickLanguageChange = (e) => {
    const language = e.target.value;
    i18n.changeLanguage(language); //change the language
  }
 
  return (
    <div className='flex justify-between items-center h-18 w-full mx-auto px-4 sm:py-0 dark:text-slate-100 text-slate-900 dark:bg-black bg-white shadow'>
      <h1 className='z-4 w-full text-3xl font-bold'><Link to="/">f<span className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500'>AI</span>nds</Link></h1>
      <ul className='hidden md:flex font-semibold'>
        {(() => {
          if (sessionStorage.getItem('faindsAdminToken')) {
            return <>
              <Link to="/dashboard" className='p-4 hover:text-slate-500 flex-none'>Dashboard</Link>
            </>
          } else {
          }
        })()}
        {login ?  <>
                  <Link to="/upload" className='p-4 hover:text-slate-500'>Search</Link>
                  <Link to="/login" onClick={() => localStorage.removeItem('faindsToken')} className='p-4 hover:text-slate-500 flex-none'>Logout</Link>
                  </>:
                  <Link to="/login" className='p-4 hover:text-slate-500 flex-none'>Login</Link>
        }
        <select className='font-medium hover:text-slate-500 border-0 cursor-pointer focus:ring-0 focus:outline-none dark:bg-black bg-white' onChange={onClickLanguageChange}>
          <option value="en">English</option>
          <option value="de">German</option>
        </select>
      </ul>
      <div className='flex flex-row'>
        <div onClick={handleNav} className={nav ? 'z-3 flex w-full h-full' : 'w-full flex h-full right-[-100%]'}></div>
          <div onClick={handleNav} className='flex items-center justify-center md:hidden z-3'>
            <div className='flex items-center justify-center'>
              {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
            </div>
          </div>
        <button className="mx-3" onClick={darkMode}>
              <div className="flex bg-gradient-to-r from-teal-500 to-cyan-500 items-center w-[50px] h-[25px] transition-all rounded-full">
                <div className="dark:text-slate-900 text-slate-50 dark:translate-x-[0px] translate-x-[22px] w-[20px] ml-1 h-[20px] transition-all duration-500 items-center flex rounded-full">
                {dark ? <RiMoonClearLine size={20}/> : <RiSunFill size={20}/> }
                </div>
              </div>
        </button>
      </div>
      <ul className={nav ? 'z-5 fixed left-0 top-0 w-[50%] h-screen ease-in-out duration-500' : 
                           'z-5 ease-in-out duration-500 z-1 top-0 h-screen w-[50%] fixed left-[-100%]'}>
        <div className={dark ? 'z-5 h-full bg-gradient-to-r from-black via-black' : 'z-5 h-full bg-gradient-to-r from-white via-white'}>
        <h1 className='z-4 w-full text-3xl font-bold px-4 md:py-3'>f<span className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500'>AI</span>nds</h1>
          {(() => {
            if (sessionStorage.getItem('faindsAdminToken')) {
              return <>
                <li className='p-4 font-semibold hover:text-slate-500'><Link to="/dashboard">Dashboard</Link></li>
              </>
            } else {
            }
          })()}
          {login ? <>
                      <li className='p-4 font-semibold hover:text-slate-500'><Link to="/upload">Search</Link></li>
                      <li className='p-4 font-semibold hover:text-slate-500'><Link to="/login" onClick={() => localStorage.removeItem('faindsToken')}>Logout</Link></li>
                    </>:
                   <li className='p-4 font-semibold hover:text-slate-500'><Link to="/login">Login</Link></li>
          }
          <li className='pl-1 py-3 font-semibold hover:text-slate-500'>
            <select className='font-medium hover:text-slate-500 border-0 cursor-pointer focus:ring-0 focus:outline-none dark:bg-black bg-white' onChange={onClickLanguageChange}>
              <option value="en">Englisch</option>
              <option value="de">German</option>
            </select>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default NavbarTool;
