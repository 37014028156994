import React from 'react'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Contact from '../components/Contact';

function ContactPage() {

  return (
    <>
        <Navbar />
        <Contact />
        <Footer />
    </>
  )
}

export default ContactPage
