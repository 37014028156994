import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {IoArrowDownCircleOutline} from "react-icons/io5";
import Phone from "../assets/phone.png"
import Typed from 'react-typed';
import ProfileOneHome from '../components/ProfileOneHome';
import SingleProfileOneHome from '../components/SingleProfileOneHome';
import PreviewProfilesHome from '../components/PreviewProfilesHome';


function Home() {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false)
  let navigate = useNavigate();


  function handleClick() {
    setOpen(!open);
  }

  const [loaded, setLoaded] = useState(false);

  function handleLoad () {
    setLoaded(true);
  };

  useEffect(() => {
    handleLoad()
  }, []);

  return (
    <div>
      <div className='flex flex-col h-screen dark:text-slate-100 text-slate-900 dark:bg-[#141414] bg-[#f1f3f2]'>
        <Navbar />
        <div className='flex flex-col justify-center max-w-[800px] w-auto mx-auto text-center'>
            <p className='md:text-xl text-lg font-bold text-gray-500 pt-2'>Stop </p>
            <h1 className='md:text-3xl sm:text-2xl text-xl font-bold'>
              <span className={`${loaded ? "transition duration-[3000ms] opacity-100" : "opacity-0"}`}> Searching Filtering Comparing </span>
            </h1>
            <p className='md:text-xl text-lg font-bold text-gray-500 pt-2'>Start using</p>
        </div>
        <div className='relative grow flex pt-2'>
          <img src={Phone} alt='phone-hero' className='mt-auto sm:max-w-[360px] h-max mx-auto'/>
          <h1 className='absolute w-full top-20 font-zona font-bold text-center mx-auto text-5xl pb-2 px-2'>
            f<span className={`${loaded ? "transition delay-150 duration-[3000ms] opacity-100" : "opacity-0"} text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500`}>AI</span>nds
          </h1>
          <div className='absolute top-40 w-full flex flex-col justify-center mx-auto text-center'>
            <div className='w-1/2 sm:w-52 mx-auto p-2 text-sm sm:h-44 h-1/3 text-slate-900 dark:text-slate-100 cursor-auto rounded-lg border-2 border-dashed bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400'>
              <Typed
                strings={[t("home.exampleText")]}
                typeSpeed={40}
              />
            </div>
            <div className='w-52 pt-4 mx-auto'>
            {t("home.exampleTextStart")}
            </div>
          </div>
        </div>

        <div className='absolute animate-bounce bottom-2 w-full'>
          <a href='#result'><IoArrowDownCircleOutline className='mx-auto' size={40}/></a>
        </div>
      </div>

      <div id='result' className='h-screen relative bg-blue-300'>
        <p className='pt-8 px-2 mx-auto text-center'>{t("home.exampleTextEnd")}</p>
        <ProfileOneHome/>
        <PreviewProfilesHome/>
        <p className='mt-2 px-2 font-bold mx-auto text-center'>{t("home.moreCandidates")}</p>
        <div className='absolute animate-bounce bottom-2 w-full'>
          <a href='#details'><IoArrowDownCircleOutline className='mx-auto w-fit' size={40}/></a>
        </div>
      </div>

      <div id='details' className='h-full dark:bg-black bg-slate-50'>
      <p className='pt-4 px-2 text-slate-900 dark:text-slate-100 mx-auto text-center'>{t("home.detailedResult")}</p>

        <SingleProfileOneHome/>
      </div>
      <button 
        onClick={() => {
          navigate("/contact");
        }}
        className='bg-gradient-to-r from-teal-500 to-cyan-500 w-[200px] justify-center flex rounded-md font-medium my-6 mx-auto py-3 text-black'>Contact Us
      </button>

      <Footer />
    </div>
  );
}

export default Home;