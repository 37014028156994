import React from 'react'
import { useState } from 'react';

function FreeText({onSubmit}) {
  const [message, setMessage] = useState('');

  const handleMessageChange = event => {
    setMessage(event.target.value);
    // console.log(event.target.value);
  };

  return <>
    <div className="max-w-[800px] text-white cursor-auto mx-auto shadow-xl p-4 pb-14 mb-4 rounded-lg">
      <textarea 
        id="message"
        name="message"
        value={message}
        onChange={handleMessageChange}
        className='text-justify cursor-auto p-2 w-full h-48 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
        placeholder="Write your own requirements for the candidate you're looking for..."
        >
      </textarea>
    </div>
    <button 
      onClick={() => onSubmit(message)}
      className=' bg-[#6EA8D8] hover:bg-[#1b6099] w-[200px] justify-center flex rounded-md font-medium mb-6 mt-12 mx-auto py-3 text-black'>Submit
    </button>
  </>
}

export default FreeText