import React from 'react'
import { useNavigate } from 'react-router-dom';

function ErrorHero() {
let navigate = useNavigate();
  return (
        <div className='text-white bg-black'>
            <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
                <h1 className='text-[#6EA8D8] text-4xl font-bold py-4'>
                Whoops! Our AI couldn't find this Page.
                </h1>
                <p className='md:text-2xl text-xl font-bold text-gray-500'>
                Not to worry. You can just head over to our Homepage.</p>
                <div className="px-4 py-8 text-right sm:px-6">
                    <button
                        onClick={() => {
                            navigate("/");
                        }}
                        type="submit"
                        className=' bg-[#6EA8D8] hover:bg-[#1b6099] w-[200px] justify-center flex rounded-md font-medium my-6 mx-auto py-3 text-black'>
                        👉 Home
                    </button>
                  </div>
            </div>
            
        </div>
  )
}

export default ErrorHero