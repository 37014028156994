import React, { useState } from 'react'
import { FaQuestion, FaTimes, FaUndo } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { HiBarsArrowDown, HiBarsArrowUp } from "react-icons/hi2";
import { IoFilter } from "react-icons/io5";
import ResultFilter from './ResultFilter';


const Toolbar = () => {
    let navigate = useNavigate();
    const [faq, setFaq] = useState(false);
    const [filter, setFilter] = useState(false);

    const handleFaq = () => {
        setFaq(!faq);
    };    

    const handleFilter = () => {
        setFilter(!filter);
    }; 

    return (
        <div className='flex justify-center'>
            <div className='z-30 fixed w-full sm:w-[200px] bg-slate-100 dark:bg-black sm:bg-inherit sm:dark:bg-inherit sm:ring-transparent ring-1 ring-[#03a4aa]/80 rounded-t-xl bottom-0 h-[8%] flex justify-evenly'>
                <div className='p-1 my-auto'>
                    <div className="flex-shrink-0 cursor-pointer shadow w-10 h-10 rounded-full bg-gradient-to-r from-teal-500 to-cyan-500 inline-flex items-center justify-center">
                        <div className='block text-slate-50 dark:text-black'
                            onClick={() => {navigate("/upload")}}>
                            <FaUndo size={20}/>
                        </div>
                    </div>
                </div>

                {/* <div className='p-1 my-auto'>
                    <div className="flex-shrink-0 cursor-pointer shadow w-10 h-10 rounded-full bg-gradient-to-r from-teal-500 to-cyan-500 inline-flex items-center justify-center">
                        <div className='block text-slate-50 dark:text-black'
                            onClick={handleFilter}>
                            {filter? <FaTimes size={25}/> : <IoFilter size={25}/>}
                        </div>
                    </div>
                </div> */}

                {/* <div className={filter ? '-bottom-[2000px]' : '-bottom-[2000px]'}>
                    <ResultFilter/>
                </div> */}
                <div className='p-1 my-auto'>
                    <div className="flex-shrink-0 cursor-pointer shadow w-10 h-10 rounded-full bg-gradient-to-r from-teal-500 to-cyan-500 inline-flex items-center justify-center">
                        <div onClick={handleFaq} className='block text-slate-50 dark:text-black'>
                            {faq ? <FaTimes size={25}/> : <FaQuestion size={20} />}
                        </div>
                    </div>
                </div>
            </div>

            <div className={filter ? 'z-20 fixed right-0 overflow-auto scrollbar-hide bottom-[8%] sm:pt-8 sm:h-full sm:bottom-0 sm:overflow-hidden w-full h-[92%] pt-6 bg-slate-50 dark:bg-black ease-in-out duration-1000 delay-150' : 
                                'z-20 ease-in-out duration-1000 fixed delay-150 right-0 -bottom-[100%] h-[92%] w-full rounded-xl bg-slate-50 dark:bg-black'}>
            <ResultFilter callhandlefilter={() => handleFilter()}/>
            </div>

            <div className={faq ? 'z-20 fixed pr-8 right-0 bottom-0 pb-20 p-2 w-full h-auto rounded-xl text-slate-900 dark:text-slate-100 bg-slate-50 dark:bg-black ease-in-out duration-1000 delay-150' : 
                                'z-20 ease-in-out duration-1000 fixed delay-150 right-0 -bottom-[100%] p-2 w-full h-auto rounded-xl bg-slate-50 dark:bg-black'}>
                
                <div className='flex flex-inline mb-2 items-center justify-center'>
                    <FaQuestion size={15} className='mr-2'/>
                    <p className='font-semibold text-lg'>FAQ - Die meist gestellten Fragen</p>
                </div>
                <details className='mt-2 w-full group'>
                    <summary className='justify-center flex cursor-pointer w-full mx-auto font-medium group-open:text-slate-400 list-none'>
                        <HiBarsArrowDown size={20} className='inline mb-1 mr-1 group-open:hidden'/>
                        <HiBarsArrowUp size={20} className=' mb-1 mr-1 hidden group-open:inline'/>
                        Wie sagt die % Zahl aus?
                    </summary>
                    <p className='justify-center flex m-2 mt-0 text-sm'>Wie gut der Kandidat zu den Suchkriterien passt, basierend auf Job, Berufserfahrung und Skills.</p>
                </details>
                <details className='mt-2 w-full group'>
                    <summary className='justify-center flex cursor-pointer w-full mx-auto font-medium group-open:text-slate-500 list-none'>
                        <HiBarsArrowDown size={20} className='inline mb-1 mr-1 group-open:hidden'/>
                        <HiBarsArrowUp size={20} className=' mb-1 mr-1 hidden group-open:inline'/>
                        Wie starte ich eine neue Suche?
                    </summary>
                    <p className='justify-center flex m-2 mt-0 text-sm'>Durch klicken auf den Kreispfeil können Sie eine neue Suche starten.</p>
                </details>
                <details className='mt-2 w-full group'>
                    <summary className='justify-center flex cursor-pointer w-full mx-auto font-medium group-open:text-slate-500 list-none'>
                        <HiBarsArrowDown size={20} className='inline mb-1 mr-1 group-open:hidden'/>
                        <HiBarsArrowUp size={20} className=' mb-1 mr-1 hidden group-open:inline'/>
                        Was bedeutet der Rang?
                    </summary>
                    <p className='justify-center flex text-sm m-2 mt-0'>Der Rang ist absteigend von A bis H, sortiert nach popularität.</p>
                </details>
            </div>
        </div>
    )
}

export default Toolbar