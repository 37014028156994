import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ErrorHero from '../components/ErrorHero';

export default function ErrorPage() {
  return (
    <div>
      <Navbar />
      <ErrorHero />
      <Footer />
    </div>
  )
}
