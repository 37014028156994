import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function ConstructionPage() {
  return (
    <div>
        <Navbar />
        <div className='dark:bg-black bg-slate-50 dark:text-slate-100 text-slate-900'>
            <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
                <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500 text-4xl font-bold py-4'>
                This Site is still under construction...
                </h1>
                <h1 className='text-xl font-bold py-4'>
                f<span className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500'>AI</span>nds consists of a team of 3 developers.
                </h1>
                <p className='md:text-2xl text-xl font-bold text-gray-500'>
                    Our goal in to finally give any company the ability to use deep science to better compete in today's Recruting environment. To find the right employees, with the right Skillset without the tideous Work.
                </p>
            </div>
        </div>

        <Footer />
    </div>
  )
}

export default ConstructionPage