import React from 'react'
import { useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import NavbarTool from '../components/NavbarTool';
import SingleProfile from '../components/SingleProfile';

function ProfilePage() {
    const location = useLocation();
    // const profileData = location.state;
    const profileData = JSON.parse(localStorage.getItem('singleprofile'))
    // console.log(profileData)

  return (
    <div>
        <NavbarTool />
        <div className=''>
        {<SingleProfile
            key = {Math.random()}
            id = {Math.random()}
            name = {profileData.name}
            location = {profileData.location}
            current_industry = {profileData.current_industry}
            distance = {profileData.distance}
            companys = {profileData?.companys}
            career = {profileData?.career}
            education = {profileData.education}
            skills = {profileData.skills}
            languages = {profileData.languages}
            score = {profileData.score}
            general_insights = {profileData?.general_insights}
            time_insights = {profileData.time_insights}
            company_insights = {profileData.company_insights}
            education_insights = {profileData.education_insights}
            job_insights = {profileData.job_insights}
            link = {profileData.link} 
            twitter = {profileData.twitter}
            personal_website = {profileData.personal_website} 
            contacting = {profileData.contacting}          
        />}
        </div>
        <Footer />
    </div>
  )
}

export default ProfilePage