import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { RiMoonClearLine, RiSunFill } from "react-icons/ri";
import "../../node_modules/flag-icons/css/flag-icons.min.css"


const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const [dark, setDark] = useState(localStorage.theme === "dark" ? true : false);
  const [login] = useState(localStorage.faindsToken ? true : false);
  // console.log(login)

  const darkMode = () => {
    setDark(!dark);
  };

  useEffect(() => {
    if (dark) {
      localStorage.theme = "dark";
      document.documentElement.classList.add("dark");
    } else {
      localStorage.theme = "light";
      document.documentElement.classList.remove("dark");
    }
  }, [dark]);

  const { t, i18n } = useTranslation();

  const onClickLanguageChange = (e) => {
    const language = e.target.value;
    i18n.changeLanguage(language); //change the language
  }


  return (
    <header className='w-full dark:text-slate-100 text-slate-900 dark:bg-black bg-white shadow'>
      <div className='flex flex-row items-center justify-between h-14 mx-auto px-4 sm:py-0'>
        <h1 className='z-40 text-3xl font-bold'><Link to="/">f<span className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500'>AI</span>nds</Link></h1>
        <ul className='hidden md:flex font-semibold'>
          <Link to="/" className='p-4 hover:text-slate-500 flex-none'>{t("navbar.home")}</Link>
          <Link to="/solution" className='p-4 hover:text-slate-500 flex-none'>{t("navbar.solution")}</Link>
          <Link to="/about" className='p-4 hover:text-slate-500 flex-none'>{t("navbar.about")}</Link>
          <Link to="/contact" className='p-4 hover:text-slate-500 flex-none'>{t("navbar.contact")}</Link>
          {(() => {
              if (sessionStorage.getItem('faindsAdminToken')) {
                return <>
                  <Link to="/dashboard" className='p-4 hover:text-slate-500 flex-none'>{t("navbar.dashboard")}</Link>
                </>
              } else {
              }
            })()}
          {login ?  
                    <Link to="/upload" className='p-4 hover:text-slate-500'>{t("navbar.tool")}</Link>
                    :
                    <Link to="/login" className='p-4 hover:text-slate-500 flex-none'>{t("navbar.login")}</Link>
          }
          <select className='font-medium hover:text-slate-500 border-0 cursor-pointer focus:ring-0 focus:outline-none dark:bg-black bg-white' onChange={onClickLanguageChange}>
            <option value="en">Englisch</option>
            <option value="de">German</option>
          </select>
        </ul>
        <div className='flex'>
          <div onClick={handleNav} className={nav ? 'z-30 flex w-full h-full' : 'w-full flex h-full right-[-100%]'}></div>
            <div onClick={handleNav} className='flex mr-4 items-center justify-center md:hidden z-30'>
              <div className='flex items-center justify-center'>
                {nav ? <AiOutlineClose size={25}/> : <AiOutlineMenu size={25} />}
              </div>
            </div>
          <button className="" onClick={darkMode}>
                <div className="flex bg-gradient-to-r from-teal-500 to-cyan-500 items-center w-[50px] h-[25px] transition-all rounded-full">
                  <div className="dark:text-slate-900 text-slate-50 dark:translate-x-[0px] translate-x-[22px] w-[20px] ml-1 h-[20px] transition-all duration-500 items-center flex rounded-full">
                  {dark ? <RiMoonClearLine size={20}/> : <RiSunFill size={20}/> }
                  </div>
                </div>
          </button>
        </div>
        <ul className={nav ? 'z-30 fixed left-0 top-0 w-[50%] h-screen ease-in-out duration-500' : 
                            'ease-in-out duration-500 z-10 top-0 h-screen w-[50%] fixed left-[-100%]'}>
          <div className={dark ? 'h-full bg-gradient-to-r from-black via-black' : 'h-full bg-gradient-to-r from-white via-white'}>
          <h1 className='w-full text-3xl font-bold px-4 py-3 text-transparent'>f<span className='text-transparent'>AI</span>nds</h1>
            <li className='p-4 font-semibold hover:text-slate-500'><Link to="/">{t("navbar.home")}</Link></li>
            <li className='p-4 font-semibold hover:text-slate-500'><Link to="/solution">{t("navbar.solution")}</Link></li>
            <li className='p-4 font-semibold hover:text-slate-500'><Link to="/about">{t("navbar.about")}</Link></li>
            <li className='p-4 font-semibold hover:text-slate-500'><Link to="/contact">{t("navbar.contact")}</Link></li>
            {(() => {
              if (sessionStorage.getItem('faindsAdminToken')) {
                return <>
                  <li className='p-4 font-semibold hover:text-slate-500'><Link to="/dashboard">{t("navbar.dashboard")}</Link></li>
                </>
              } else {
              }
            })()}
            {login ? <>
                        <li className='p-4 font-semibold hover:text-slate-500'><Link to="/upload">{t("navbar.tool")}</Link></li>
                        <li className='p-4 font-semibold hover:text-slate-500'><Link to="/login" onClick={() => localStorage.removeItem('faindsToken')}>{t("navbar.logout")}</Link></li>
                      </>:
                    <li className='p-4 font-semibold hover:text-slate-500'><Link to="/login">{t("navbar.login")}</Link></li>
            }
            <li className='pl-1 py-3 font-semibold hover:text-slate-500'>
              <select className='font-medium hover:text-slate-500 border-0 cursor-pointer focus:ring-0 focus:outline-none dark:bg-black bg-white' onChange={onClickLanguageChange}>
                <option value="en">Englisch</option>
                <option value="de">German</option>
              </select>
            </li>
          </div>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
