import React from 'react'
import { Link } from 'react-router-dom';
import Login from '../components/Login';

function LoginPage() {
  return (
      <div className='h-screen'>
          <Login />
          <div className='dark:text-slate-100 text-slate-500 dark:hover:text-slate-300 hover:text-slate-300 justify-end flex pr-4'><Link to="/admin">pssst... Admin? Klick hier.</Link></div>
      </div>
  )
}

export default LoginPage;
