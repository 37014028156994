import React from 'react'
import { useRef, useState, useEffect, useContext } from 'react';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from '../UserPool';
import { AiFillLock } from 'react-icons/ai';
import { useNavigate, Link } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';

import axios from 'axios';
// const LOGIN_URL = 'https://fainds-backend.herokuapp.com/login';
const LOGIN_URL = 'http://127.0.0.1:8000/login'
// const LOGIN_URL2 = 'https://fainds-backend.herokuapp.com/testen';

const SignIn = () => {
    let navigate = useNavigate();
    const {setAuth} = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [check, setCheck] = useState()
    
    const handleCheck = (event) => {
        setCheck(event.target.checked)
        localStorage.setItem('remember',`${event.target.checked}`)
        // console.log(!check)
    }

    useEffect(() => {
        if (localStorage.getItem("username") !== null && localStorage.getItem("remember") === 'true') {
        setEmail(localStorage.getItem("username"))
        } else {
        //   console.log('No Username in localStorage')
          localStorage.removeItem('username');
          setEmail('')
        }
      }, [success]);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, password])

    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //         const response = axios.post(LOGIN_URL,
    //             {'email': email, 'password': password},
    //             {
    //                 header: { 'Content-Type': 'application/json' },
    //             }
    //             ).then(response=>{
    //                 // console.log(response)
    //                 localStorage.setItem('faindsToken', `${response?.data?.access_token}`)
    //                 if (localStorage.getItem("remember") === 'true') {
    //                     localStorage.setItem('username', `${response?.data?.username}`)
    //                 }
    //                 // setAuth({ user, pwd, accessToken})
    //                 // set the variables from the response
    //                 setSuccess(true);
    //                 navigate("/upload");
    //             })
    //             .catch(err => {
    //             if (!err?.response) {
    //                 setErrMsg('No Server Response');
    //             } else if (err.response?.status === 400) {
    //                 setErrMsg('Missing Email or Password')
    //             } else if (err.response?.status === 401) {
    //                 setErrMsg('Unauthorized')
    //             } else {
    //                 setErrMsg('Login Failed')
    //             }
    //             errRef.current.focus();
    //             })
    // }

        const handleSubmit = (event) => {
            event.preventDefault();
    
            const user = new CognitoUser({
                Username: email,
                Pool: UserPool
            });
    
            const authDetails = new AuthenticationDetails({
                Username: email,
                Password: password
            });
    
            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    UserPool.getCurrentUser().getSession((err,session) =>{
                        if (err) {
                            console.log(err)
                        } else {
                            // console.log(session)
                            localStorage.setItem('faindsToken', `${session.getIdToken().getJwtToken()}`)
                            if (localStorage.getItem("remember") === 'true') {
                                localStorage.setItem('username', `${session?.idToken.payload.email}`)
                            }
                        }})
                        navigate("/upload");
                },
                onFailure: (err) => {
                    if (!err?.response) {
                        setErrMsg('No Server Response');
                    } else if (err.response?.status === 400) {
                        setErrMsg('Missing Email or Password')
                    } else if (err.response?.status === 401) {
                        setErrMsg('Unauthorized')
                    } else {
                        setErrMsg('Login Failed')
                    }
                    errRef.current.focus();                
                }
            });
        }

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
            <div>
                <h1 className='text-center w-auto mx-auto text-5xl font-bold text-white'><Link to="/">f<span className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500'>AI</span>nds</Link></h1>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-white">Sign in to your account</h2>
                <p className="mt-2 text-center text-sm text-gray-200">
                Or{' '}
                <a href="/contact" className="font-medium text-gray-500 hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500">
                    contact us to register
                </a>
                </p>
            </div>
            <form onSubmit={handleSubmit} className="mt-8 space-y-6" action="#" method="POST">
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                <div>
                    <label htmlFor="email-address" className="sr-only">
                    Email address
                    </label>
                    <input
                    id="email-address"
                    type="email"
                    autoComplete="off"
                    ref={userRef}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                    placeholder='Email'
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-[#03a4aa] focus:border-[#03a4aa] focus:z-10 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="password" className="sr-only">
                    Password
                    </label>
                    <input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    required
                    placeholder='Password'
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-[#03a4aa] focus:border-[#03a4aa] focus:z-10 sm:text-sm"
                    />
                </div>
                </div>

                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <input
                        id="check"
                        name="check"
                        type="checkbox"
                        checked={localStorage.getItem("remember") === 'true'}
                        onChange={handleCheck}
                        className="h-4 w-4 text-[#03a4aa] focus:ring-[#03a4aa] border-gray-300 rounded"
                        />
                        <label htmlFor="check" className="ml-2 block text-sm text-white">
                        Remember me
                        </label>
                    </div>

                    <div className="text-sm">
                        <a href="/contact" className="font-medium text-white hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500">
                        Forgot your password?
                        </a>
                    </div>
                </div>

                <div>
                <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 text-sm font-medium rounded-md text-black bg-gradient-to-r from-teal-500 to-cyan-500 hover:bg-[#1b6099]"
                >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <AiFillLock className="h-5 w-5 text-black" aria-hidden="true" />
                    </span>
                    Sign in
                </button>
                </div>
                <p ref={errRef} className={errMsg ? "flex justify-center font-medium text-red-400" : "absolute left-9999:"} aria-live="assertive">
                    {errMsg}
                </p>
            </form>
            </div>
        </div>
  )
}

export default SignIn;
