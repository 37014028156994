import React, { useState } from 'react'
import { FaCloudUploadAlt, FaFileAlt, FaFilePdf } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import pdf_file from "../assets/pdf_file.png";
import { useTranslation } from 'react-i18next';


function Upload({onSubmit, onLoading}) {
  if(localStorage.getItem("message") === null) {
    localStorage.setItem("message", "")
  }
  const localMessage = localStorage.getItem("message")
  const Characterlimit = 2048;
  const [view, setView] = useState(true);
  // const [loading, setLoading] = useState(false);
  // const [limit, setLimit] = useState(false);
  const [message, setMessage] = useState(localMessage);

  // useEffect(() => {
  //   if (localStorage.getItem("message").length > 0) {
  //     setsavedMessage(localStorage.getItem("message"))
  //   } else {
  //   //   console.log('No Username in localStorage')
  //     localStorage.removeItem('message');
  //     setsavedMessage('')
  //   }
  // }, [success]);

  const handleView = () => {
      setView(!view);
  };

//   const handleLoading = () => {
//     setLoading(!loading);
// };

  const handleMessageChange = event => {
    // console.log(event.target.value.length)
    if(event.target.value.length+1 > Characterlimit){ 
      window.alert("Character limit for Freetext reached!")
      // setLimit(true)
    }
    else {
      // setLimit(false)
    }
    localStorage.setItem("message", event.target.value);
    setMessage(event.target.value);
    // console.log(event.target.value);
  };


  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
        'application/pdf': ['.pdf']
    }
  });

  // console.log(acceptedFiles[0]?.name)
  const { t } = useTranslation();

  return (
    <>
  <div className='w-full dark:bg-black bg-slate-50 '>
    <div className='relative flex flex-row max-w-[600px] mx-auto'>
{/* Freetext Upload Area*/}
      <div className={view ? "w-full py-4 pl-4 pr-2 text-gray-500 ease-in-out duration-1000 delay-150" : 
                             "w-14 dark:bg-black bg-slate-50 my-4 rounded-lg ease-in-out duration-1000 delay-150"}>
        <div onClick={handleView} className={view ? 'absolute z-30 w-0 sm:h-96 h-80 rounded-lg ease-in-out duration-1000 delay-150' : 
                                                    'absolute z-0 bg-gradient-to-r from-teal-500 to-cyan-500 w-14 sm:h-96 h-80 rounded-lg cursor-pointer ease-in-out duration-1000 delay-150'}>
          <div className={view ? 'flex px-0 pt-4 justify-center items-center text-transparent ease-in-out duration-1000' : 
                                 'flex px-0 pt-4 justify-center items-center text-slate-100 dark:text-slate-900 mx-auto ease-in-out duration-1000'}>
            <FaFileAlt size={30} />
          </div>
          <p className={view ? "transform text-transparent text-[0px] font-medium text-xl rotate-180 my-28 mx-auto ease-in-out duration-1000" :
                               "transform text-slate-100 dark:text-slate-900 font-medium text-xl rotate-180 sm:my-16 my-4 mx-auto ease-in-out duration-1000"
                               } 
                               style={{ writingMode: 'vertical-rl' }}>
            Wechsel zum Freitext
          </p>
        </div>
        <textarea 
          id="message"
          name="message"
          maxLength="2048"
          value={message}
          onChange={handleMessageChange}
          className={view ? 'text-slate-900 dark:text-slate-100 text-justify cursor-auto w-full sm:h-96 h-80 rounded-lg border-2 border-dashed bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 ease-in-out duration-700 delay-150' : 
                            "text-transparent text-[0px] text-justify cursor-auto w-14 sm:h-96 h-80 rounded-lg border-2 border-dashed bg-slate-50 hover:bg-gray-800 border-gray-600 hover:border-gray-500 ease-in-out duration-1000 delay-150"}
          placeholder={t("upload.exampleText")}
          >   
        </textarea>
        <div className='flex' bg={`${message.length > Characterlimit ? 'danger' : 'primary'}`}>{message.length}/{Characterlimit}
        {(() => {
          if (message.length === Characterlimit) {
            return <>
            
              <div className={view ? 'absolut pl-2 w-full text-red-400 ease-in-out duration-1000 delay-150' : 
                                     'absolut pl-2 w-full text-transparent ease-in-out duration-1000 delay-150'}>
                                    {t("upload.characterLimit")}
              </div>                
            </>
          } else {
            return <>
            <div className='pl-2 w-full'> {t("upload.characters")} </div>
            </>
          }
        })()}
        </div>
      </div>

{/* PDF Upload Area*/}

      {/* <div className= {view ? "w-14 h-80 sm:h-96 text-transparent dark:bg-black bg-slate-50 my-4 rounded-lg ease-in-out duration-700 delay-150" : 
                              'w-full sm:h-96 h-80 p-4 text-gray-500 ease-in-out duration-700 delay-150'}>
        <div onClick={handleView} className={view ? 'absolute z-0 bg-gradient-to-r from-teal-500 to-cyan-500 w-14 sm:h-96 h-80 rounded-lg cursor-pointer ease-in-out duration-1000 delay-150' : 
                                                    'absolute z-30 w-0 sm:h-96 h-80 rounded-lg ease-in-out duration-1000 delay-150'}>
          <div className={view ? 'flex pt-4 justify-center items-center text-slate-100 dark:text-slate-900 mx-auto ease-in-out duration-1000' : 
                                 'flex pt-4 justify-center items-center text-transparent ease-in-out duration-1000'}>
            <FaFilePdf size={30} />
          </div>
          <p className={view ? "transform text-slate-100 dark:text-slate-900 font-medium text-xl rotate-180 sm:my-10 my-4 mx-auto ease-in-out duration-1000" : 
                               "transform text-transparent text-[0px] font-medium text-xl rotate-180 my-24 mx-auto ease-in-out duration-1000"} 
                               style={{ writingMode: 'vertical-rl' }}>
            Wechsel zum PDF Upload
          </p>
        </div>

        {acceptedFiles[0]==null ?   
          <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="flex flex-col w-full justify-center items-center sm:h-96 h-80 rounded-lg border-2 border-dashed cursor-pointer bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400">
                  <FaCloudUploadAlt size={40} className="mb-3"/>
                  <p className="text-center mb-2 px-4 text-sm"><span className="font-semibold">Klicken zum Hochladen</span> oder Drag und Drop eine Stellenbeschreibung.</p>
                  <p className="text-xs">PDF (MAX. 50mb)</p>
              </div>
          </div>
          :
          <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className='flex flex-col justify-center items-center text-center cursor-pointer sm:h-96 h-80 pr-14'>
                <img src={pdf_file} alt="PDf Icon" className='w-24 h-24'/>
                <div>
                  <p className='text-center font-semibold text-slate-900 dark:text-slate-100 pt-4'>{acceptedFiles[0]?.path} </p>
                </div>
              </div>
          </div>
        }
      </div> */}
    </div>

{/* Switching between the Submit-Buttons PDF and Freetext*/}

          <div className='flex items-center justify-center py-14'>
            {/* <div className={view ? 'absolute z-0' : "absolute z-20"}>
              <button 
                onClick={() => {onSubmit(acceptedFiles[0]); onLoading()}}
                className='bg-gradient-to-r from-teal-500 to-cyan-500 hover:bg-[#1b6099] w-[200px] justify-center flex rounded-md font-medium mx-auto py-3 dark:text-slate-900 text-slate-100 z-20'>
                Suche starten
              </button>
            </div> */}

            <div className={view ? 'absolute z-20' : "absolute z-0"}>
              <button 
                disabled={message.length > 5 ? false : true}
                onClick={() => {onSubmit(message); onLoading()}}
                className='bg-gradient-to-r from-teal-500 to-cyan-500 hover:bg-[#1b6099] w-[200px] justify-center flex rounded-md font-medium mx-auto py-3 dark:text-slate-900 text-slate-100'>
                {t("upload.startSearch")}
              </button>
            </div>
          </div>               
    </div>
    </>
  )
}

export default Upload