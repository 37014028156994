import React from 'react'
import { CheckIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next';

const Welcome = () => {

  const { t } = useTranslation();

  return (
    <div className='w-full px-4 dark:bg-black bg-slate-50 dark:text-slate-100 text-slate-900 sm:text-xl text-medium font-bold border-gray-600 pb-4 pt-8'>
      <div className='max-w-[1024px] justify-center mx-auto flex'>
        {/* <p>Willkommen zurück <span className='pl-1 text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500'>Romik Kelesh</span></p> */}
        <details>
        <summary className='cursor-pointer w-fit text-slate-900 dark:text-slate-100 dark:text-slate-100 font-medium justify-center mx-auto mb-2 w-full flex flex-inline'>
        {/* <IoFilter size={25} className='inline mr-2'/> */}
        <p> <span className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500 font-bold '>{t("welcome.summary")} </span>🤔 </p>
        <p> {t("welcome.click")}</p>
        </summary>
        <div className='text-sm text-slate-700 dark:text-slate-300 pb-1'>
          <p>{t("welcome.detailOne")}</p>
          <p>{t("welcome.detailTwo")}</p>
          <p className='py-1'>{t("welcome.detailThree")}</p>
          <div className='p-2 pt-0'>
            <div className='flex'>
              <CheckIcon className="h-5 w-5"/>
              <p>{t("welcome.detailJob")}</p>
            </div>
            <div className='flex'>
              <CheckIcon className="h-5 w-5"/>
              <p>{t("welcome.detailWork")}</p>
            </div>
            <div className='flex'>
              <CheckIcon className="h-5 w-5"/>
              <p>{t("welcome.detailSkills")}</p>
            </div>
          </div>
          <p>{t("welcome.detailFive")}</p>
          <p className='text-red-300 pt-2'>{t("welcome.detailImportantOne")}</p>
          <p>{t("welcome.detailImportantTwo")}</p>
          </div>
        </details>
        </div>
    </div>
  )
}

export default Welcome;
