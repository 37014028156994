import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { VscFileSymlinkFile } from "react-icons/vsc";


function PreviewProfilesHome() {

  return (
      <div className='flex justify-center mx-auto max-w-[400px]'>

        <div className='flex justify-center mx-auto px-2'>
          <div className="flex flex-col p-2 text-slate-900 dark:text-slate-200 bg-white dark:bg-gray-900 shadow rounded-xl">
            <div className='flex flex-row h-fit'>
              <div className='font-medium'>
                <h3 className="text-sm">Elizabeth Swann</h3>
                <h3 className="text-xs">Data Scientist</h3>
              </div>
              <div className='ml-4 w-10 h-10'>
                <CircularProgressbar styles={{ text: {fill: "#03a4aa"}, path: { stroke:"#03a4aa"}, trail: { stroke:"transparent"}}} strokeWidth="12" value={92} text={`92%`} />
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-center mx-auto px-2'>
          <div className="flex flex-col p-2 text-slate-900 dark:text-slate-200 bg-white dark:bg-gray-900 shadow rounded-xl">
            <div className='flex flex-row h-fit'>
              <div className='font-medium'>
                <h3 className="text-sm">Will Turner</h3>
                <h3 className="text-xs">Data Scientist</h3>
              </div>
              <div className='ml-4 w-10 h-10'>
                <CircularProgressbar styles={{ text: {fill: "#03a4aa"}, path: { stroke:"#03a4aa"}, trail: { stroke:"transparent"}}} strokeWidth="12" value={86} text={`86%`} />
              </div>
            </div>
          </div>
        </div>

      </div>
  )
}

export default PreviewProfilesHome