import React from 'react'
import AdminLogin from '../components/AdminLogin';

function AdminLoginPage() {
  return (
    <div className='h-screen'>
        <AdminLogin />
    </div>
  )
}

export default AdminLoginPage