import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react'
import HomePage from './Pages/HomePage';
import AppUploadPage from './Pages/AppUploadPage';
import ErrorPage from './Pages/ErrorPage';
import LoginPage from './Pages/LoginPage';
import SolutionPage from './Pages/SolutionPage';
// import AboutPage from './Pages/AboutPage';
import ContactPage from './Pages/ContactPage';
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage';
import ImpressumPage from './Pages/ImpressumPage';
import AppResultPage from './Pages/AppResultPage';
import AppFreeTextPage from './Pages/AppFreeTextPage';
import ConstructionPage from './Pages/ConstructionPage';
import ProfilePage from './Pages/ProfilePage';
import Protected from './context/Protected';
import ProtectedAdmin from './context/ProtectedAdmin';
import AdminPanel from './Pages/AdminPanel';
import AdminLoginPage from './Pages/AdminLoginPage';

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

function App() {
  return (
    <BrowserRouter>
    <Wrapper>
      <Routes>
        <Route path="/" element={<HomePage />}/>
        <Route path="/login" element={<LoginPage />}/>
        <Route path='/admin' element={<AdminLoginPage/>}/>
        <Route path="/upload" element={
          <Protected>
            <AppUploadPage/>
          </Protected>
        }/>
        <Route path='/freetext' element={<AppFreeTextPage/>}/>
        <Route path='/results' element={
          <Protected>
            <AppResultPage/>
          </Protected>
        }/>
        <Route path='/dashboard' element={
          <ProtectedAdmin>
            <AdminPanel />
          </ProtectedAdmin>
        }/>
        <Route path='/profile' element={<ProfilePage/>}/>
        <Route path='/solution' element={<SolutionPage />}/>
        <Route path='/about' element={<ConstructionPage />}/>
        <Route path='/contact' element={<ContactPage />}/>
        <Route path='/privacy' element={<PrivacyPolicyPage />}/>
        <Route path='/impressum' element={<ImpressumPage />}/> 
        <Route path="*" element={<ErrorPage />}/>
      </Routes>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App
