import React, { useState } from 'react'
import axios from 'axios';
import NavbarAdmin from '../components/NavbarAdmin';
import { useEffect } from 'react';
import { 
  IoSchoolOutline,
  IoBriefcaseOutline,
  IoLanguageOutline,
  IoTimeOutline,
  IoBulbOutline,
  IoChatboxEllipsesOutline,
  IoLocationOutline,
  IoCalendarOutline
} from "react-icons/io5";

function AdminPanel() {
  const [data, setData] = useState([])

    const fetchdata = async() => {
      await axios({
        method: "get",
        url: "https://ktisf68cwl.execute-api.eu-central-1.amazonaws.com/Prod-V1/get_data",
        headers: {
            Authorization: sessionStorage.getItem('faindsAdminToken'),
        },
      }).then((response) => {
        const allData = response.data.data
        setData(allData)
      })
    }

      useEffect(() => {
        fetchdata()
      }, []);

  // console.log(data)

  return (
    <div className='dark:text-white text-black dark:bg-black bg-white h-auto min-h-screen'>
      <NavbarAdmin/>
      <div className='group max-w-[800px] w-auto sm:justify-center sm:pt-0 justify-start mx-auto text-center flex flex-col relative pb-4'>
        <p className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500 text-lg font-bold p-2 pt-4'>
          Dashboard
        </p>
      </div>
        <div>
            {data.map(users => (
              <div className='border dark:border-gray-600 border-gray-400 dark:bg-gray-800 bg-gray-200 rounded p-2 m-2 mb-4'>
                <details>
                  <summary className='flex justify-between cursor-pointer'>{users.email} <span>{users.queries.length}</span></summary> 
                      {users.queries.slice(0).reverse().map(queries => (
                        <details>
                            <summary className='flex flex-wrap px-2 py-2 sm:px-2 text-base leading-6 font-medium text-slate-900 dark:text-slate-200 bg-white dark:bg-gray-900 shadow rounded-t-xl mt-2 cursor-pointer'>
                              <IoCalendarOutline size={20} className='mr-4 inline'/>
                              {queries.date}
                            </summary>

                            <div className='flex flex-col justify-center mx-auto'>
                              {/* Candidate Preview Section */}
                              <div className="flex flex-wrap justify-between px-4 sm:px-6 text-slate-900 dark:text-slate-200 bg-white dark:bg-gray-900 shadow rounded-b-xl">
                                <div className='flex max-w-[400px]'>
                                  <div className='shrink w-[300px] mb-2'>
                                    <p className='font-medium dark:text-slate-400 text-slate-500 text-sm'>Eingabe:</p>

                                    <div className='w-full my-2 text-slate-700 dark:text-slate-300 font-medium text-sm'>
                                      <div className="flex-shrink-0 flex">
                                        <div className='self-center'>
                                          <IoChatboxEllipsesOutline size={25} className='inline mr-1 pb-1 text-black dark:text-white'/>
                                        </div>
                                        <div className='flex flex-col border-l dark:border-white border-black'>
                                          <p className='flex-none mx-2'>
                                            {queries.query}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <p className='font-medium dark:text-slate-400 text-slate-500 text-sm'>Extrahiert:</p>

                                    <div className='w-full my-2 text-slate-700 dark:text-slate-300 font-medium text-sm'>
                                      <div className="flex-shrink-0 flex">
                                        <div className='self-center'>
                                          <IoBulbOutline size={25} className='inline mr-1 pb-1 text-black dark:text-white'/>
                                        </div>
                                        <div className='flex flex-col border-l dark:border-white border-black'>
                                            {queries.skills.map(skill => (
                                            <p className='flex-none mx-2'>
                                              {skill}
                                            </p>
                                            ))}
                                        </div>
                                      </div>
                                    </div>

                                    <div className='w-full my-2 text-slate-700 dark:text-slate-300 font-medium text-sm'>
                                      <div className="flex-shrink-0 flex">
                                        <div className='self-center'>
                                          <IoTimeOutline size={25} className='inline mr-1 pb-1 text-black dark:text-white'/>
                                        </div>
                                        <div className='flex flex-col border-l dark:border-white border-black'>
                                          <p className='flex-none mx-2'>
                                            {queries.work_exp} jahr(e)
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='w-full my-2 text-slate-700 dark:text-slate-300 font-medium text-sm'>
                                      <div className="flex-shrink-0 flex">
                                        <div className='self-center'>
                                          <IoLocationOutline size={25} className='inline mr-1 pb-1 text-black dark:text-white'/>
                                        </div>
                                        <div className='flex flex-col border-l dark:border-white border-black'>
                                          <p className='flex-none mx-2'>
                                            {queries.location[0]?.stadt}
                                          </p>
                                          <p className='flex-none mx-2 text-slate-500'>
                                            {queries.location[0]?.bundesland}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>

                            <details className='w-full inline right-0 my-1 mr-1 font-medium text-white dark:text-black shadow shadow-[#03a4aa] px-2 py-1 rounded-md bg-gradient-to-r from-teal-500 to-cyan-500 hover:bg-[#1b6099]'>
                              <summary className='text-center cursor-pointer'>Ergebnisse:</summary>
                            <div className='flex flex-wrap mx-auto sm:justify-start justify-center'>
                              {queries?.candidates.map(candidates => (
                                <div className='flex mx-1'>
                                  {/* Candidate Preview Section */}
                                  <div className="flex flex-wrap max-w-[400px] lg:max-w-[490px] justify-between px-2 py-3 sm:px-6 text-slate-900 dark:text-slate-200 bg-white dark:bg-gray-900 shadow rounded-xl my-1">
                                    <div className='flex max-w-[400px]'>
                                      <div className='shrink w-[300px] mb-2'>
                                        <h3 className="text-2xl leading-6 font-medium ">{candidates.name}</h3>
                                        <h3 className="text-lg leading-6 font-medium">{candidates.job_titles[0]}</h3>
                                        <p className='font-medium dark:text-slate-400 text-slate-500 text-sm'>{candidates.location[0]?.stadt}</p>

                                        <div className='w-full my-2 text-slate-700 dark:text-slate-300 font-medium text-sm'>
                                          <div className="flex-shrink-0 flex">
                                            <div className='self-center'>
                                              <IoSchoolOutline size={25} className='inline mr-1 pb-1 text-black dark:text-white'/>
                                            </div>
                                            <div className='flex flex-col border-l dark:border-white border-black'>
                                              <p className='flex-none ml-2'>
                                                {candidates.edu_titles[0]}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="flex-shrink-0 flex pt-2">
                                            <div className='self-center'>
                                              <IoLanguageOutline size={25} className='inline mr-1 pb-1 text-black dark:text-white'/>
                                            </div>
                                            <div className='flex flex-col border-l dark:border-white border-black'>
                                              <p className='flex-wrap ml-2'>
                                                {candidates.languages?.map(language => (
                                                  <a>{language.language} </a>
                                                ))}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="flex-shrink-0 flex pt-2">
                                            <div className='self-center'>
                                              <IoTimeOutline size={25} className='inline mr-1 pb-1 text-black dark:text-white'/>
                                            </div>
                                            <div className='flex flex-col border-l dark:border-white border-black'>
                                              <p className='flex-inline ml-2'>Arbeitserfahrung:</p>
                                              <p className='flex-none ml-2'>
                                              {(() => {
                                                if (candidates.total_work_exp.total_year === 0) {
                                                  return <></>
                                                }
                                                else if (candidates.total_work_exp.total_year > 1){
                                                  return <>
                                                  <p className='inline'> {candidates.total_work_exp.total_year} jahre </p>
                                                  </>
                                                } else {
                                                  return <>
                                                  <p className='inline'> {candidates.total_work_exp.total_year} jahr </p>
                                                  </>
                                                }
                                              })()}

                                              {(() => {
                                                if (candidates.total_work_exp.total_month === 0) {
                                                  return <></>
                                                }
                                                else if (candidates.total_work_exp.total_month > 1){
                                                  return <>
                                                  <p className='inline'>  {candidates.total_work_exp.total_month} Monate</p>
                                                  </>
                                                } else {
                                                  return <>
                                                  <p className='inline'>  {candidates.total_work_exp.total_month} Monat</p>
                                                  </>
                                                }
                                              })()}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="flex-shrink-0 flex pt-2">
                                            <div className='self-center'>
                                              <IoBulbOutline size={25} className='inline mr-1 pb-1 text-black dark:text-white'/>
                                            </div>
                                            <div className='flex-col border-l dark:border-white border-black'>
                                              <p className='flex-none ml-2'>
                                              <details>
                                                <summary className='cursor-pointer w-fit font-medium'>Detailiertes Skillset</summary>
                                                <div className='flex flex-wrap'>
                                                  {candidates.skills.map((skill) => (
                                                    <div className='shadow-sm dark:shadow-black py-1 px-2 m-1 bg-white dark:bg-gray-900 rounded-xl'>{skill} </div>
                                                  ))}
                                                </div>
                                                </details>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              
                              ))}
                          </div>
                          </details>

                        </details>
                      ))}
                </details>
              </div>
            ))}
        </div>
    </div>
  )
}

export default AdminPanel