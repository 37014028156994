import React from 'react'
import axios from 'axios';
import { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';


function Contact() {
  const [data, setData] = useState({
      pronouns: "",
      first_name: "",
      last_name: "",
      email:"",
      company_name: "",
      position: "",
      phone: "",
      message: "",
    })
    
  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      pronouns: data.pronouns,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      company_name: data.company_name,
      position: data.position,
      phone: data.phone,
      message: data.message
    };
    await axios({
      method:"post",
      url: "https://sc8oqenus2.execute-api.eu-central-1.amazonaws.com/test/contact", 
      data: userData
    }).then((response) => {
      // console.log(response);
      contactSuccessToast();
      setData({   
        pronouns: "",  
        first_name: "",
        last_name: "",
        email:"",
        company_name: "",
        position: "",
        phone: "",
        message: "" })
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        console.log("server responded");
      } else if (error.request) {
        console.log("network error");
      } else {
        console.log(error);
      }
    });
  };

  const contactSuccessToast = ()  => {
    toast.success(t("contact.toast"), {
      icon: '📬',
      style: {
        borderRadius: '10px',
        background: '#03a4aa',
        color: 'black',
      },
    })
    }

  const { t } = useTranslation();

  return (
    <div>
      <datalist id="pronouns_list">
          <option value="Frau"/>
          <option value="Herr"/>
          <option value="Neutral"/>
      </datalist>
        <section className="text-gray-600 body-font relative bg-gray-50 dark:bg-black">
            <div className="container px-5 py-8 mx-auto flex sm:flex-nowrap flex-wrap">
                <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                <iframe width="100%" height="100%" className="absolute inset-0" frameBorder="0" title="map" marginHeight="0" marginWidth="0" scrolling="no" src="https://maps.google.com/maps?width=800&amp;height=200&amp;hl=en&amp;q=im%20mediapark%205%20K%C3%B6ln+(fAInds)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                <div className="bg-white dark:bg-gray-900 relative flex flex-wrap sm:py-6 py-3 mt-32 rounded shadow-md">
                    <div className="lg:w-1/2 px-6">
                    <h2 className="title-font font-semibold text-slate-900 dark:text-slate-100 tracking-widest text-xs">{t("contact.adress")}</h2>
                    <p className="mt-1 text-slate-900 dark:text-slate-300">Im Mediapark 5, 50670 Köln</p>
                    </div>
                    <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                    <h2 className="title-font font-semibold text-slate-900 dark:text-slate-100 tracking-widest text-xs">E-MAIL</h2>
                    <a href= "mailto:info@fAInds.com" className='mt-2 text-slate-6 00 hover:text-black dark:text-slate-300 dark:hover:text-slate-100'> info@f<span className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500'>AI</span>nds.com</a>
                    </div>
                </div>
                </div>
                <div className="lg:w-1/3 md:w-1/2 bg-gray-50 dark:bg-black flex flex-col md:ml-auto w-full sm:mt-0 mt-8">
                <h2 className="text-slate-900 dark:text-slate-200 text-lg mb-1 font-medium title-font">{t("contact.contact")}</h2>
                <p className="leading-relaxed mb-5 text-slate-700 dark:text-slate-300">{t("contact.intro")}</p>
                <form onSubmit={handleSubmit}>
                <div className="relative mb-4">
                  <label htmlFor="pronouns" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                  {t("contact.address")}
                  </label>
                  <input
                      required="required"
                      type="text"
                      name="pronouns"
                      id="pronouns"
                      list='pronouns_list'
                      placeholder="Wie möchtest du angesprochen werden?"
                      value={data.pronouns}
                      onChange={handleChange}
                      className="mt-1 border-2 bg-slate-50 border-slate-200 hover:border-slate-400 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />                
                </div>
                <div className="relative mb-4">
                  <label htmlFor="name" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                  {t("contact.firstname")}
                  </label>
                  <input
                    required="required"
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder="Was ist dein Vorname?"
                    value={data.first_name}
                    onChange={handleChange}
                    className="mt-1 border-2 bg-slate-50 border-slate-200 hover:border-slate-400 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />                
                </div>
                <div className="relative mb-4">
                  <label htmlFor="email" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                  {t("contact.lastname")}
                  </label>
                  <input
                    required="required"
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder="Was ist dein Nachname?"
                    value={data.last_name}
                    onChange={handleChange}
                    className="mt-1 border-2 bg-slate-50 border-slate-200 hover:border-slate-400 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />                
                </div>
                <div className="relative mb-4">
                  <label htmlFor="email" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                    E-Mail*
                  </label>
                  <input
                    required="required"
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Wie lautet deine E-Mail?"
                    value={data.email}
                    onChange={handleChange}
                    className="mt-1 border-2 bg-slate-50 border-slate-200 hover:border-slate-400 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className='relative mb-4'>
                  <label htmlFor="company_name" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                  {t("contact.company")}
                  </label>
                  <input
                    required="required"
                    type="text"
                    name="company_name"
                    id="company_name"
                    placeholder="Wie heißt eurer Unternehmen?"
                    value={data.company_name}
                    onChange={handleChange}
                    className="mt-1 border-2 bg-slate-50 border-slate-200 hover:border-slate-400 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className='relative mb-4'>
                  <label htmlFor="position" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                  {t("contact.position")}
                  </label>
                  <input
                    type="text"
                    name="position"
                    id="position"
                    placeholder="Welche Position besetzt du?"
                    value={data.position}
                    onChange={handleChange}
                    className="mt-1 border-2 bg-slate-50 border-slate-200 hover:border-slate-400 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className='relative mb-4'>
                  <label htmlFor="phone" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                  {t("contact.phonenumber")}
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Wie ist deine Telefonnummer?"
                    value={data.phone}
                    onChange={handleChange}
                    className="mt-1 border-2 bg-slate-50 border-slate-200 hover:border-slate-400 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="relative mb-4">
                  <label htmlFor="message" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                  {t("contact.message")}
                  </label>
                  <textarea id="message" name="message" className="mt-1 border-2 bg-slate-50 border-slate-200 hover:border-slate-400 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full h-32 shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
                </div>
                <button 
                  type='submit' 
                  className="bg-gradient-to-r from-teal-500 to-cyan-500 hover:bg-[#1b6099] w-full justify-center flex rounded-md font-medium mx-auto py-2 text-black">
                  {t("contact.send")} 📧
                </button>
                </form>
                <p className="text-xs text-gray-500 mt-3">
                {t("contact.datasec")}
                </p>
            </div>
          </div>
        </section>
    </div>
  )
}

export default Contact