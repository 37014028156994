import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import toast, { Toaster } from 'react-hot-toast';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import {
    FaExternalLinkSquareAlt,
    FaLinkedin,
    FaTwitterSquare,
    // FaFacebookSquare,
    // FaGithubSquare,
    // FaXingSquare,
    FaQuoteLeft,
    FaQuoteRight,
    FaUserTie
} from 'react-icons/fa';
import { 
    IoInformationCircleOutline,
    IoLocationOutline, 
    IoBriefcaseOutline,
    IoTimeOutline,
    IoSchoolOutline,
    IoArrowUpOutline,
    IoBusiness,
    IoBulbOutline,
    IoHeartDislikeOutline,
    IoHeartOutline,
    IoThumbsUpOutline,
    IoThumbsDownOutline,
    IoCashOutline,
    IoWarning,
    IoCodeWorkingOutline
} from "react-icons/io5";
import { RiQuestionMark } from "react-icons/ri";

function SingleProfile() {

  const { t } = useTranslation();

  return (
    <div key={Math.random()}>
    <div className='bg-slate-50 dark:bg-black min-h-screen'>
        <div className='flex flex-col w-full sm:w-2/3 justify-center mx-auto px-2'>
          {/* Candidate Preview Section */}
          <div className="flex flex-wrap px-4 py-5 sm:px-6 bg-white dark:bg-gray-900 text-slate-900 dark:text-slate-300 rounded-xl my-4">

          <div className='w-full flex flex-wrap justify-between mx-auto'>  
          <div className='flex max-w-[400px]'>
            <div className='shrink w-[300px] mb-2'>
              <h3 className="text-2xl leading-6 font-medium ">Jack Sparrow</h3>
                <h3 className="text-lg leading-6 font-medium">Data Scientist</h3>
              <div className='font-medium dark:text-slate-400 text-slate-500 text-sm'>
                <p>Bochum, North Rhine-Westphalia, Germany</p>
              </div>
            </div>
            <div className='ml-4 sm:w-[20%] max-w-[80px] flex-row'>
              <CircularProgressbar styles={{ text: {fill: "#03a4aa"}, path: { stroke:"#03a4aa"}, trail: { stroke:"transparent"}}} strokeWidth="12" value={100} text={`100%`} />
            </div>
          </div>

            <div className='w-fit flex flex-row my-6 sm:ml-1'>
              <form>
              <button 
                className='flex mr-2 shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium w-fit'>
                <FaLinkedin size={25} />
                <div className='px-2'>LinkedIn</div>
              </button>
              </form>
              {/* Function to check if there is a Twitter Link attached to the Profile */}
              <form>
              <button 
                className='flex mr-2 shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium w-fit'>
                <FaTwitterSquare size={25} />
                <div className='px-2'>Twitter</div>
              </button>
              </form>
              {/* Function to check if there is a Personal Website Link attached to the Profile */}
              <form>
              <button 
                className='flex shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium w-fit'>
                <FaExternalLinkSquareAlt size={25}/>
                <div className='px-2'>Portfolio</div>
              </button>
              </form>           
            </div>
            </div>

{/* Company Insights */}

          <div className='w-full px-4 py-4 sm:px-6 mb-4 shadow dark:shadow-black rounded-xl'>
            <h3 className="leading-6 font-medium text-xl dark:text-slate-100 mb-2">{t("singleProfilesHome.companyInsights")}</h3>
            <div className='font-medium text-sm'>
              <p className="mt-1">{t("singleProfilesHome.industry")}: <span className='text-gray-500 dark:text-gray-400'>Chemie</span></p>
              <p>Job: <span className='text-gray-500 dark:text-gray-400'>Data Scientist {t("singleProfilesHome.at")} Caribic GmbH</span></p>
              <p>{t("singleProfilesHome.location")}: <span className='text-gray-500 dark:text-gray-400'>Bochum</span></p>
              <p>{t("singleProfilesHome.distance")}: <span className='text-gray-500 dark:text-gray-400'>XX km</span></p>
            </div>

{/* Company Insights - Detailed Information about current Company */}

                  <details className='mt-2 mb-2'>
                    <summary className='cursor-pointer w-fit dark:text-slate-100 font-medium'>{t("singleProfilesHome.detailedInformation")} Caribic GmbH</summary>
                    <div className="border-t border-gray-200">
                      <div className='mb-4'></div>
                      <div className='max-w-2xl font-medium text-sm'>
                        <div className="flex-shrink-0 flex mb-4">
                          <div className='self-center'>
                            <IoBusiness size={25} className='inline mr-1 pb-1'/>
                          </div>
                          <div className='flex flex-col'>
                            <p className='flex-none'>
                              {t("singleProfilesHome.culture")}
                            </p>
                            <p className='flex-none'>
                              {t("singleProfilesHome.industry")}: Offentliche Verwaltung
                            </p>
                          </div>
                        </div>
                        <div className='flex flex-inline mb-1'>
                          <IoCashOutline size={25} className='inline mr-1 pb-1'/>
                          <p className='inline mb-4'>
                            {t("singleProfilesHome.salarySatisfaction")}
                          </p>
                        </div>
                      </div>

                      <h1 className="leading-relaxed mb-1 font-medium">
                        {t("singleProfilesHome.whatEmployees")}:
                      </h1>                          

{/* Company Insights - Detailed Information about current Company - Comments*/}

                            <details className='ml-4 mt-2 mb-4'>
                                <summary className='cursor-pointer shadow dark:shadow-black rounded-lg p-1 pr-2 w-full flex flex-inline'>
                                <IoHeartOutline size={25} className='inline mr-1 pb-1'/>
                                <div className="leading-relaxed font-medium text-sm">
                                  {t("singleProfilesHome.employeesLove")}
                                </div>
                              </summary>
                              <div className='font-medium mt-4 text-sm text-gray-500'>
                                <div className='flex w-fit mb-4 p-3 rounded-xl shadow'>
                                  <FaQuoteLeft size={8} className='flex-none mr-2'/>
                                    {t("singleProfilesHome.employeesLoveResult")}
                                  <FaQuoteRight size={8} className='flex-none ml-2'/>
                                </div>
                              </div>
                            </details>

                            <details className='ml-4 mt-2 mb-4'>
                                <summary className='cursor-pointer shadow dark:shadow-black rounded-lg p-1 pr-2 w-full flex flex-inline'>
                                <IoHeartDislikeOutline size={25} className='inline mr-1 pb-1'/>
                                <div className="leading-relaxed font-medium text-sm">
                                  {t("singleProfilesHome.employeesHate")} Work-Life-Balance
                                </div>
                              </summary>
                              <div className='font-medium mt-4 text-sm text-gray-500'>
                                <div className='flex w-fit mb-4 p-3 rounded-xl shadow'>
                                  <FaQuoteLeft size={8} className='flex-none mr-2'/>
                                    {t("singleProfilesHome.employeesHateResult")}
                                  <FaQuoteRight size={8} className='flex-none ml-2'/>
                                </div>
                              </div>
                            </details>
 
                            <details className='ml-4 mt-2 mb-4'>
                              <summary className='cursor-pointer shadow dark:shadow-black rounded-lg p-1 pr-2 w-full flex flex-inline'>
                                <IoThumbsUpOutline size={25} className='inline mr-1 pb-1'/>
                                <div className="leading-relaxed font-medium text-sm">
                                  {t("singleProfilesHome.employeesLike")}...
                                </div>
                              </summary>
                              <div className='font-medium mt-4 text-sm text-gray-500'>
                                <div className='flex w-fit mb-4 p-3 rounded-xl shadow'>
                                  <FaQuoteLeft size={8} className='flex-none mr-2'/>
                                    {t("singleProfilesHome.employeesLikeResult")}
                                  <FaQuoteRight size={8} className='flex-none ml-2'/>
                                </div>
                              </div>
                            </details>

                            <details className='ml-4 mt-2 mb-4'>
                                <summary className='cursor-pointer shadow dark:shadow-black rounded-lg p-1 pr-2 w-full flex flex-inline'>
                                <IoThumbsDownOutline size={25} className='inline mr-1 pb-1'/>
                                <div className="leading-relaxed font-medium text-sm">
                                  {t("singleProfilesHome.employeesDislike")}...
                                </div>
                              </summary>
                              <div className='font-medium mt-4 text-sm text-gray-500'>
                                <div className='flex w-fit mb-4 p-3 rounded-xl shadow'>
                                  <FaQuoteLeft size={8} className='flex-none mr-2'/>
                                    {t("singleProfilesHome.employeesDislikeResult")}
                                  <FaQuoteRight size={8} className='flex-none ml-2'/>
                                </div>
                              </div>
                            </details>
                      
                            <details className='ml-4 mt-2 mb-4'>
                                <summary className='cursor-pointer shadow dark:shadow-black rounded-lg p-1 pr-2 w-full flex flex-inline'>
                                <IoBulbOutline size={25} className='inline mr-1 pb-1'/>
                                <div className="leading-relaxed font-medium text-sm">
                                  {t("singleProfilesHome.employeesSuggest")}...
                                </div>
                              </summary>
                              <div className='font-medium mt-4 text-sm text-gray-500'>
                                <div className='flex w-fit mb-4 p-3 rounded-xl shadow'>
                                  <FaQuoteLeft size={8} className='flex-none mr-2'/>
                                    {t("singleProfilesHome.employeesSuggestResult")}
                                  <FaQuoteRight size={8} className='flex-none ml-2'/>
                                </div>
                              </div>
                            </details>

                    </div>
                  </details>

{/* Company Insights - Detailed Company History*/}

            <div className='max-w-[300px] font-medium text-sm'>
              {t("singleProfilesHome.workedFor")} C.
            </div>
            <details className='mt-2'>
              <summary className='cursor-pointer w-fit dark:text-slate-100 font-medium'>{t("singleProfilesHome.companyHistory")}</summary>
              <div className="border-t border-gray-200">
                <div className='mb-8'></div>
                  {/* Erste Firma */}
                  <div className="flex relative pb-6">
                      <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 dark:bg-black pointer-events-none"></div>
                      </div>
                    <div className="flex-shrink-0 shadow w-10 h-10 rounded-full bg-[#03a4aa] inline-flex items-center justify-center dark:text-black text-white relative z-10">
                      <div className='mb-0.5 text-2xl'>
                        C
                      </div>
                    </div>
                    <div className="flex-grow pl-4">
                      <div className='flex flex-inline'>
                        <IoBriefcaseOutline size={25} className='flex-none mr-1 pb-1'/>
                        <h2 className="font-medium title-font text-sm mb-1 tracking-wide">
                          Lead Data Scientist<span className='text-slate-500'> - </span> Schiffe GmbH
                        </h2>
                      </div>
                        <div className='flex flex-inline'>
                          <IoLocationOutline size={25} className='inline mr-1 pb-1'/>
                          <p className="leading-relaxed mb-1 font-medium text-sm">
                            Tortuga
                          </p>
                        </div>
                      <p className="leading-relaxed font-medium text-sm text-gray-400">
                        <IoTimeOutline size={25} className='inline pb-1'/> Okt. 2021 - {t("singleProfilesHome.today")}</p>
                    </div>
                  </div>
                  {/* Zweite Firma */}
                  <div className="flex relative pb-6">
                      <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 dark:bg-black pointer-events-none"></div>
                      </div>
                    <div className="flex-shrink-0 shadow w-10 h-10 rounded-full bg-[#03a4aa] inline-flex items-center justify-center dark:text-black text-white relative z-10">
                      <div className='mb-0.5 text-2xl'>
                        A
                      </div>
                    </div>
                    <div className="flex-grow pl-4">
                      <div className='flex flex-inline'>
                        <IoBriefcaseOutline size={25} className='flex-none mr-1 pb-1'/>
                        <h2 className="font-medium title-font text-sm mb-1 tracking-wide">
                          Data Scientist<span className='text-slate-500'> - </span> Kanone
                        </h2>
                      </div>
                        <div className='flex flex-inline'>
                          <IoLocationOutline size={25} className='inline mr-1 pb-1'/>
                          <p className="leading-relaxed mb-1 font-medium text-sm">
                            London
                          </p>
                        </div>
                      <p className="leading-relaxed font-medium text-sm text-gray-400">
                        <IoTimeOutline size={25} className='inline pb-1'/>Sept. 2020-Sept. 2021</p>
                    </div>
                  </div>
                  {/* Dritte Firma */}
                  <div className="flex relative pb-6">
                      <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 dark:bg-black pointer-events-none"></div>
                      </div>
                    <div className="flex-shrink-0 shadow w-10 h-10 rounded-full bg-[#03a4aa] inline-flex items-center justify-center dark:text-black text-white relative z-10">
                      <div className='mb-0.5 text-2xl'>
                        D
                      </div>
                    </div>
                    <div className="flex-grow pl-4">
                      <div className='flex flex-inline'>
                        <IoBriefcaseOutline size={25} className='flex-none mr-1 pb-1'/>
                        <h2 className="font-medium title-font text-sm mb-1 tracking-wide">
                          Data Scientist<span className='text-slate-500'> - </span> Segel
                        </h2>
                      </div>
                        <div className='flex flex-inline'>
                          <IoLocationOutline size={25} className='inline mr-1 pb-1'/>
                          <p className="leading-relaxed mb-1 font-medium text-sm">
                            Bochum
                          </p>
                        </div>
                      <p className="leading-relaxed font-medium text-sm text-gray-400">
                        <IoTimeOutline size={25} className='inline pb-1'/>Jan. 2020-März 2020</p>
                    </div>
                  </div>

              </div>
            </details>
          </div>

{/* Company Insights - Career Insights*/}
          
          <div className='w-full px-4 py-4 sm:px-6 mb-4 bg-white dark:bg-gray-900 shadow dark:shadow-black rounded-xl'>
            <h3 className="leading-6 font-medium text-xl text-black dark:text-white mb-2">{t("singleProfilesHome.careerInsights")}</h3>
            <div className='font-medium'>
              <div className='text-sm'>
                <div className='flex flex-wrap ml-2'>
                  {t("singleProfilesHome.duration")}:
                  <p className='flex ml-1 text-gray-500 dark:text-gray-400'> 4 {t("singleProfilesHome.years")} 6 {t("singleProfilesHome.months")}</p>
                </div>
                <div className='flex flex-wrap ml-2'>
                  Ø {t("singleProfilesHome.durationEmployment")}:
                  <p className='flex ml-1 text-gray-500 dark:text-gray-400'> 1 {t("singleProfilesHome.years")} 4 {t("singleProfilesHome.months")}</p>
                </div>
              </div>

{/* Company Insights - Career Insights - Gaps*/}

              <details className='mt-2'>
                <summary className='cursor-pointer font-medium text-black dark:text-white'>1 {t("singleProfilesHome.gaps")}</summary>
                  <div className="border-t border-gray-200">
                    <div className='mb-4'></div>
                      <div>
                        <div className='ml-4 mb-4'>
                          <div className='text-sm'>
                            <div className='flex flex-inline'>
                            <IoCodeWorkingOutline size={25} className='pt-1 mr-1'/>
                            <p className='py-1'>März 2020 - Sept. 2020</p>
                            </div>
                            <div className='flex flex-inline text-gray-400'>
                              <IoTimeOutline size={25} className='pb-1'/>
                                <p className='flex-inline ml-1'> 5 {t("singleProfilesHome.months")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </details>

{/* Company Insights - Career Insights - Short Periods*/}

                <details className='mt-2'>
                  <summary className='cursor-pointer font-medium text-black dark:text-white'>1 {t("singleProfilesHome.shortterm")}</summary>
                    <div className="border-t border-gray-200">
                      <div className='mb-4'></div>
                        <div className='ml-4 mb-4'>
                          <div className='text-sm'>
                            <div className='flex flex-inline'>
                              <IoBriefcaseOutline size={25} className='mr-1 pb-1'/>
                              <p className='py-1'>Data Scientist</p>
                            </div>
                            <div className='flex flex-inline'>
                              <IoTimeOutline size={25} className='mr-1 pb-1'/>
                              <p className='text-gray-400'>3 {t("singleProfilesHome.months")}Monate - Jan. 2020-März 2020</p>
                            </div>
                          </div>
                        </div>
                    </div>
                </details>

{/* Company Insights - Career Insights - Lead positions*/}

                <details className='mt-2'>
                  <summary className='cursor-pointer font-medium text-black dark:text-white'>1 {t("singleProfilesHome.leadershipPositions")}</summary>
                    <div className="border-t border-gray-200">
                      <div className='mb-4'></div>
                        <div className='ml-4 mb-4'>
                          <div className='text-sm'>
                            <div className='flex flex-inline'>
                              <FaUserTie size={25} className='mr-1 pb-1'/>
                              <p className='py-1'>Lead Data Scientist</p>
                            </div>
                            <div className='flex flex-inline'>
                              <IoTimeOutline size={25} className='mr-1 pb-1'/>
                              <p className='text-gray-400'>1 {t("singleProfilesHome.years")} 9 {t("singleProfilesHome.months")}</p>
                            </div>
                          </div>
                        </div>
                    </div>
                </details>

{/* Company Insights - Career Insights - Relocations*/}

                <details className='mt-2'>
                  <summary className='cursor-pointer font-medium text-black dark:text-white'>{t("singleProfilesHome.relocated")} 1 {t("singleProfilesHome.relocated2")}</summary>
                    <div className="border-t border-gray-200">
                      <div className='mb-4'></div>
                          {/* Erster Ort */}
                          <div className='ml-4 mb-1'>
                            <div className='text-sm'>
                            <div className='flex flex-inline'>
                              <IoLocationOutline size={25} className='flex-none mr-1 pb-1'/>
                              <h2 className="font-medium title-font text-sm mb-1 tracking-wide">
                              Bochum <span className='text-gray-500'> - </span> Schiffe Gmbh
                              </h2>
                            </div>
                              <div className='flex flex-inline'>
                                <IoArrowUpOutline size={25} className='mr-1 pb-1'/>
                              </div>
                            </div>
                          </div>
                          {/* Zweiter Ort */}
                          <div className='ml-4 mb-1'>
                            <div className='text-sm'>
                            <div className='flex flex-inline'>
                              <IoLocationOutline size={25} className='flex-none mr-1 pb-1'/>
                              <h2 className="font-medium title-font text-sm mb-1 tracking-wide">
                              Tortuga <span className='text-gray-500'> - </span> Kanone
                              </h2>
                            </div>
                              <div className='flex flex-inline'>
                                <IoArrowUpOutline size={25} className='mr-1 pb-1'/>
                              </div>
                            </div>
                          </div>
                    </div>
                </details>
            </div>
          </div>

{/* Company Insights - Education Insights*/}

          <div className='w-full px-4 py-4 sm:px-6 mb-4 bg-white dark:bg-gray-900 shadow dark:shadow-black rounded-xl'>
            <h3 className="leading-6 font-medium text-xl text-black dark:text-white mb-2">{t("singleProfilesHome.educationalInsights")}</h3>
            <div className='flex flex-col justify-center'>
              <div className="flex flex-wrap justify-between">
                <div className='font-medium text-sm'>
                  <p className='pr-1'>Doctor</p>
                  <p>Ruhr-Universitat Bochum</p>
                  <p>{t("singleProfilesHome.rank")}: C</p>
                </div>
              </div>
            </div>

{/* Company Insights - Education Insights - Detailed Education History*/}

            <details className='mt-2'>
              <summary className='cursor-pointer w-fit font-medium text-black dark:text-white'>{t("singleProfilesHome.educationalHistory")}</summary>
              <div className="border-t border-gray-200">
                <div className='mb-8'></div>
                {/* Erste Bildung */}
                  <div className="flex relative pb-6">
                      <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 dark:bg-black pointer-events-none"></div>
                      </div>
                    <div className="flex-shrink-0 shadow w-10 h-10 rounded-full bg-[#03a4aa] inline-flex items-center justify-center text-white dark:text-black relative z-10">
                      <div className='mb-0.5 text-2xl'>
                        C
                      </div>
                    </div>
                    <div className="flex-grow pl-4">
                      <div className='flex flex-inline'>
                        <IoSchoolOutline size={25} className='flex-none mr-1 pb-1'/>
                        <h2 className="font-medium title-font text-sm mb-1 tracking-wide">
                          Electrical Engineering and Information Technology<span className='text-gray-500'> - </span>Ruhr-Universitat Bochum
                        </h2>
                      </div>
                      <div className='flex flex-inline'>
                      <p className="leading-relaxed mb-1 ml-7 font-medium text-sm">
                        Dr.-Ing.
                      </p>
                      </div>
                      <div className='flex flex-inline'>
                        <IoTimeOutline size={25} className='flex-none mr-1 pb-1'/> 
                        <p className="leading-relaxed font-medium text-sm text-gray-400">
                          2013-2019
                        </p>
                      </div>
                    </div>
                  </div>
                {/* Zweite Bildung */}
                <div className="flex relative pb-6">
                      <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 dark:bg-black pointer-events-none"></div>
                      </div>
                    <div className="flex-shrink-0 shadow w-10 h-10 rounded-full bg-[#03a4aa] inline-flex items-center justify-center text-white dark:text-black relative z-10">
                      <div className='mb-0.5 text-2xl'>
                        C
                      </div>
                    </div>
                    <div className="flex-grow pl-4">
                      <div className='flex flex-inline'>
                        <IoSchoolOutline size={25} className='flex-none mr-1 pb-1'/>
                        <h2 className="font-medium title-font text-sm mb-1 tracking-wide">
                          Electrical Engineering and Information Technology<span className='text-gray-500'> - </span>Ruhr-Universitat Bochum
                        </h2>
                      </div>
                      <div className='flex flex-inline'>
                      <p className="leading-relaxed mb-1 ml-7 font-medium text-sm">
                        Diplom
                      </p>
                      </div>
                      <div className='flex flex-inline'>
                        <IoTimeOutline size={25} className='flex-none mr-1 pb-1'/> 
                        <p className="leading-relaxed font-medium text-sm text-gray-400">
                          2006-2013
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
            </details>
          </div>

{/* Company Insights - Skill Insights*/}

          <div className='w-full px-4 py-4 sm:px-6 mb-4 bg-white dark:bg-gray-900 shadow dark:shadow-black rounded-xl'>
            <h3 className="leading-6 font-medium text-xl text-black dark:text-white mb-2">{t("singleProfilesHome.skillInsights")}</h3>
            <div className='mt-4 font-medium'>
              <p className='mb-4 border-b border-gray-200 text-black dark:text-white'>3 {t("singleProfilesHome.languages")}</p>
              <div className='flex flex-wrap'>
              {/* Erste Sprache */}
              <div className="ml-4">
                <div className="flex flex-wrap pr-4">
                  <div className='flex'>
                    <h2 className="font-bold title-font text-sm mb-1 pr-1 tracking-wide">
                    {t("singleProfilesHome.german")}
                    </h2>
                  </div>
                  <div>
                    <p className="leading-relaxed mb-1 font-medium text-sm text-gray-500 dark:text-gray-400">
                      - {t("singleProfilesHome.languageLevelOne")}
                    </p>
                  </div>
                </div>
              </div>
              {/* Zweite Sprache */}
              <div className="ml-4">
                <div className="flex flex-wrap pr-4">
                  <div className='flex'>
                    <h2 className="font-bold title-font text-sm mb-1 pr-1 tracking-wide">
                    {t("singleProfilesHome.english")} 
                    </h2>
                  </div>
                  <div>
                    <p className="leading-relaxed mb-1 font-medium text-sm text-gray-500 dark:text-gray-400">
                      - {t("singleProfilesHome.languageLevelTwo")}
                    </p>
                  </div>
                </div>
              </div>
              {/* Dritte Sprache */}
              <div className="ml-4">
                <div className="flex flex-wrap pr-4">
                  <div className='flex'>
                    <h2 className="font-bold title-font text-sm mb-1 pr-1 tracking-wide">
                    {t("singleProfilesHome.dutch")} 
                    </h2>
                  </div>
                  <div>
                    <p className="leading-relaxed mb-1 font-medium text-sm text-gray-500 dark:text-gray-400">
                      - {t("singleProfilesHome.languageLevelThree")}
                    </p>
                  </div>
                </div>
              </div>

              </div>
            </div>

{/* Company Insights - Skill Insights - Detailed Skillset*/}

            <details className='mt-2'>
              <summary className='cursor-pointer w-fit font-medium text-black dark:text-white'>{t("singleProfilesHome.detailedSkillset")}</summary>
              <div className="border-t border-gray-200">
                <div className='mt-4 flex flex-wrap'>
                    <div className='shadow-sm dark:shadow-black py-1 px-2 m-1 bg-white dark:bg-gray-900 rounded-xl'>Python </div>
                    <div className='shadow-sm dark:shadow-black py-1 px-2 m-1 bg-white dark:bg-gray-900 rounded-xl'>Machine Learning </div>
                    <div className='shadow-sm dark:shadow-black py-1 px-2 m-1 bg-white dark:bg-gray-900 rounded-xl'>PyTorch </div>
                    <div className='shadow-sm dark:shadow-black py-1 px-2 m-1 bg-white dark:bg-gray-900 rounded-xl'>Data Science </div>
                    <div className='shadow-sm dark:shadow-black py-1 px-2 m-1 bg-white dark:bg-gray-900 rounded-xl'>Git </div>
                    <div className='shadow-sm dark:shadow-black py-1 px-2 m-1 bg-white dark:bg-gray-900 rounded-xl'>TensorFlow </div>
                    <div className='shadow-sm dark:shadow-black py-1 px-2 m-1 bg-white dark:bg-gray-900 rounded-xl'>Docker </div>

                </div>
              </div>
            </details>
          </div>

{/* Contacting Insights*/}

          <div className='w-full px-4 py-4 sm:px-6 mb-4 bg-white dark:bg-gray-900 shadow dark:shadow-black rounded-xl'>
            <h3 className="leading-6 font-medium text-xl text-black dark:text-white mb-2">{t("singleProfilesHome.contactProposals")}<span className='ml-1 text-xs text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500'>AI</span></h3>
            <div className='mt-4 font-medium'>
            <p className='mb-4 text-sm text-gray-500 dark:text-gray-400'>{t("singleProfilesHome.aiContact")}</p>
            </div>

{/* Contacting Insights - Contacting suggestions*/}
            <div className=''>
            <details className='mt-2'>
              <summary className='cursor-pointer w-fit font-medium text-black dark:text-white'>Option: {t("singleProfilesHome.aiSeriousLong")}</summary>
              <div className="border-t border-gray-200">
                <div className='mt-4 flex flex-wrap'>
                  <div className='font-medium mt-4 text-sm text-gray-500'>
                    <div className='flex shadow-sm dark:shadow-black w-fit mb-4 p-3 rounded-xl shadow'>
                      <FaQuoteLeft size={8} className='flex-none mr-2'/>
                        {t("singleProfilesHome.aiSeriousLongResult")}
                      <FaQuoteRight size={8} className='flex-none ml-2'/>
                    </div>
                  </div>
                </div>
              </div>
            </details>

            </div>
          </div>

        </div>
        </div>
    </div>
  </div>
  )
}

export default SingleProfile