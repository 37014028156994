import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function PrivacyPolicyPage() {
  return (
    <div>
        <Navbar/>

        <div className='dark:bg-black bg-slate-50 dark:text-slate-100 text-slate-900'>
              <div className='max-w-[800px] mt-[-96px] w-full py-32 px-8 mx-auto flex flex-col justify-center'>
                <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500 text-4xl font-bold py-4'>
                PRIVACY NOTICE
                </h1>
                <p className='md:text-xl text-xl'>
                  
                This privacy notice discloses the privacy practices for https://www.fainds.com.</p>
                <p className='md:text-xl text-xl'>This privacy notice applies solely to information collected by this website.</p>

                <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500 text-xl font-bold py-4'>Your Access to and Control Over Information </h1>

                <p className='pb-2'>You may opt out of any future contacts from us at any time. You can also do the following at any time by contacting us via email:</p>

                <p className='pl-4'>See what data we have about you, if any.</p>
                <p className='pl-4'>Change/correct any data we have about you.</p>
                <p className='pl-4'>Have us delete any data we have about you.</p>
                <p className='pl-4'>Express any concern you have about our use of your data.</p>

                <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500 text-xl font-bold py-4'>Security</h1> 
                <p className='pb-2'>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.</p>
                <p className='pb-2'>Wherever we collect sensitive information, that information is transmitted to us in a secure way. You can verify this by looking for a lock icon in the address bar and looking for "https" at the beginning of the address of the Web page.</p>

                <p>We also protect your information offline. Only employees who need the information to perform a specific job are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.</p>

                <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500 text-xl font-bold py-4'>Links</h1> 

                <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>
              </div>
          </div>

        <Footer/>
    </div>
  )
}

export default PrivacyPolicyPage