import { Navigate } from "react-router-dom";

const ProtectedAdmin = ({ children }) => {

    if (!sessionStorage.getItem('faindsAdminToken')) {
        return <Navigate to="/admin" replace />;
    }
    return children;
  
}
  
export default ProtectedAdmin;