import React from 'react'
import { useTranslation } from 'react-i18next';
import { CircularProgressbar } from 'react-circular-progressbar';
import toast, { Toaster } from 'react-hot-toast';
import 'react-circular-progressbar/dist/styles.css';
import {
    FaExternalLinkSquareAlt,
    FaLinkedin,
    FaTwitterSquare,
    // FaFacebookSquare,
    // FaGithubSquare,
    // FaXingSquare,
    FaQuoteLeft,
    FaQuoteRight,
    FaUserTie
} from 'react-icons/fa';
import { 
    IoInformationCircleOutline,
    IoLocationOutline, 
    IoBriefcaseOutline,
    IoTimeOutline,
    IoSchoolOutline,
    IoArrowUpOutline,
    IoBusiness,
    IoBulbOutline,
    IoHeartDislikeOutline,
    IoHeartOutline,
    IoThumbsUpOutline,
    IoThumbsDownOutline,
    IoCashOutline,
    IoWarning,
    IoCodeWorkingOutline
} from "react-icons/io5";
import { RiQuestionMark } from "react-icons/ri";

function SingleProfile({id, name, location, current_industry, distance, companys, career, education, skills, languages, score, general_insights, time_insights, company_insights, education_insights, job_insights, link, twitter, personal_website, contacting }) {
    const educationWithRanking = education.map((education) => {
        // here we define a new object that is the same as your object that is currently looped up to in your arrNames array
       const newEducation = education;
       // loop your second array over this currently looped to object, seeing if the name matches
       education_insights.ranked.ranked_institutions.forEach((education_insights) => {
         if (education.name === education_insights.institution) {
           newEducation.ranking = education_insights.ranking; // if they do set a new property for your new object called info as the info from item 2 of this arrInfo array
         }
       });
       
       return newEducation
     });
   
     const companysWithRanking = companys.map((companys) => {
       const newCompany = companys; // here we define a new object that is the same as your object that is currently looped up to in your arrNames array
     
       // loop your second array over this currently looped to object, seeing if the name matches
       company_insights.ranked.ranked_institutions.forEach((company_insights) => {
         if (companys.name === company_insights.institution) {
           newCompany.ranking = company_insights.ranking; // if they do set a new property for your new object called info as the info from item 2 of this arrInfo array
         }
       });
       
       return newCompany
     });

     const copySuccessToast = ()  => {
      toast.success('Text kopiert', {
        icon: '👏',
        style: {
          borderRadius: '10px',
          background: '#03a4aa',
          color: 'black',
        },
      })
     }

  const { t } = useTranslation();

  return (
    <div key={Math.random()}>
    <div className='bg-slate-50 dark:bg-black min-h-screen'>
        <div className='flex flex-col max-w-[1024px] justify-center mx-auto px-2'>
          {/* Candidate Preview Section */}
          <div className="flex flex-wrap gap-3 px-4 py-5 sm:px-6 bg-white dark:bg-gray-900 text-slate-900 dark:text-slate-300 rounded-xl my-4">
            
          <div className='flex max-w-[400px] sm:p-4 '>
              <div className='shrink w-[300px] mb-2'>
                <h3 className="text-2xl leading-6 font-medium ">{name}</h3>
                {(() => {
                  if (companys.length > 0) {
                    return <>
                    <h3 className="text-lg leading-6 font-medium">{companys[0].job_title}</h3>
                    </>
                  } else {
                    return <></>
                  }
                })()} 
                <div className='font-medium dark:text-slate-400 text-slate-500 text-sm'>
                  <p>{location}</p>
                </div>
              </div>
              {(() => {
                  if (score > 0) {
                    return <>
                      <div className='ml-4 sm:w-[20%] max-w-[80px] flex-row'>
                        <CircularProgressbar styles={{ text: {fill: "#03a4aa"}, path: { stroke:"#03a4aa"}, trail: { stroke:"transparent"}}} strokeWidth="12" value={score} text={`${Math.round(score)}%`} />
                      </div>
                    </>
                  } else {
                    return <></>
                  }
                })()}
            </div>

            <div className='w-[300px] flex flex-row my-6 sm:ml-8'>
              <>
                <form>
                  <button 
                    type='submit'
                    formAction={link} 
                    formTarget="_blank"
                    className='flex mr-2 shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium w-fit'>
                    <FaLinkedin size={25} />
                    <div className='px-2'>LinkedIn</div>
                  </button>
                </form>
              </>
                {/* Function to check if there is a Twitter Link attached to the Profile */}
                {(() => {
                  if (twitter.length > 1) {
                    return <>
                      <form>
                        <button 
                          type="submit"
                          formAction={twitter}
                          formTarget="_blank"
                          className='flex mr-2 shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium w-fit'>
                          <FaTwitterSquare size={25} />
                          <div className='px-2'>Twitter</div>
                        </button>
                      </form>
                    </>
                  } else {
                  }
                })()}
                {/* Function to check if there is a Personal Website Link attached to the Profile */}
                {(() => {
                  if (personal_website.length > 1) {
                    return <>
                      <form>
                        <button 
                          type='submit'
                          formAction={personal_website} 
                          formTarget="_blank"
                          className='flex shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium w-fit'>
                          <FaExternalLinkSquareAlt size={25}/>
                          <div className='px-2'>Portfolio</div>
                        </button>
                      </form>
                    </ >   
                  } else {
                  }
                })()}              
            </div>

            {/* <div className='flex-none w-[300px] mb-8'>
                <h3 className="text-2xl leading-6 dark:text-slate-100 font-medium">{name}</h3>
                <h3 className="text-lg leading-6 font-medium">{companys[0].job_title}</h3>
                <div className='font-medium text-slate-700 dark:text-slate-300 text-sm'>
                    <p className='pt-1'>{location}</p>
                </div>
            </div> */}

            {/* <div className='ml-4 w-[25%] max-w-[80px]'>
              <CircularProgressbar styles={{ text: {fill: "#03a4aa"}, path: { stroke:"#03a4aa"}, trail: { stroke:"transparent"}}} strokeWidth="12" value={score} text={`${Math.round(score)}%`} />
            </div> */}

            {/*
            <div className='w-[300px] flex flex-col mt-2'>
              <>
                <form>
                  <button 
                    type='submit'
                    formAction={link} 
                    formTarget="_blank"
                    className='flex w-[200px] mr-2 shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium'>
                    <FaLinkedin size={30} />
                    <div className='px-4 pt-0.5'>LinkedIn Profil</div>
                  </button>
                </form>
              </>
                {/* Function to check if there is a Twitter Link attached to the Profile */}
                {/*
                {(() => {
                  if (twitter.length > 1) {
                    return <>
                      <form>
                        <button 
                          type="submit"
                          formAction={twitter}
                          formTarget="_blank"
                          className='flex w-[200px] mr-2 mt-2 shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium'>
                          <FaTwitterSquare size={30} />
                          <div className='px-4 pt-0.5'>Twitter Profil</div>
                        </button>
                      </form>
                    </>
                  } else {
                  }
                })()}
                {/* Function to check if there is a Personal Website Link attached to the Profile */}
                {/*
                {(() => {
                  if (personal_website.length > 1) {
                    return <>
                      <form>
                        <button 
                          type='submit'
                          formAction={personal_website} 
                          formTarget="_blank"
                          className='flex w-[200px] mt-2 shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium'>
                          <FaExternalLinkSquareAlt size={30}/>
                          <div className='px-4 pt-0.5'>Portfolio</div>
                        </button>
                      </form>
                    </ >   
                  } else {
                  }
                })()}              
            </div>
          {/* </div> */}

{/* Company Insights */}

          <div className='sm:w-[450px] w-full px-4 py-4 sm:px-6 border-2 border-black shadow dark:shadow-black rounded-xl'>
            <h3 className="leading-6 font-medium text-xl dark:text-slate-100 mb-2">{t("singleProfiles.companyInsights")}</h3>
            <div className='font-medium text-sm'>
                <p className="mt-1">{t("singleProfiles.industry")}: {current_industry}</p>
                {(() => {
                  if (companys.length > 0) {
                    return <>
                    <p>Job: {companys[0].job_title} {t("singleProfiles.at")} {companys[0].name}</p>
                    </>
                  } else {
                    return <></>
                  }
                })()} 
                <p>{t("singleProfiles.location")}: {location}</p>
                {(() => {
                    if (distance) {
                      return <>
                      <p>{t("singleProfiles.distance")}: {distance} km</p>
                      </>
                  } else {
                    return <>
                    </>
                      }
                  })()}
            </div>

{/* Company Insights - Detailed Information about current Company */}

            {(() => {
              if (company_insights.inst_data !== "keine Daten zurzeit verfügbar.") {
                return <>
                  <details className='mt-2 mb-2'>
                    <summary className='cursor-pointer w-fit dark:text-slate-100 font-medium'>{t("singleProfiles.detailedInformation")} {companys[0].name}</summary>
                    <div className="border-t border-gray-200">
                      <div className='mb-4'></div>
                      <div className='max-w-2xl font-medium text-sm'>
                        <div className="flex-shrink-0 flex mb-4">
                          <div className='self-center'>
                            <IoBusiness size={25} className='inline mr-1 pb-1'/>
                          </div>
                          <div className='flex flex-col'>
                            <p className='flex-none'>
                            {t("singleProfiles.culture")}: {company_insights.inst_data.culture_result.company_culture}
                            </p>
                            <p className='flex-none'>
                            {t("singleProfiles.industry")}: {company_insights.inst_data.branch}
                            </p>
                          </div>
                        </div>
                        <div className='flex flex-inline mb-1'>
                          <IoCashOutline size={25} className='inline mr-1 pb-1'/>
                          <p className='inline mb-4'>
                          {t("singleProfiles.salarySatisfaction")}: {company_insights.inst_data.salary_result.salary_satisfaction.salay_satisfaction}
                          </p>
                        </div>
                      </div>

                      {(() => {
                        if (Object.keys(company_insights.inst_data.general_result.best_factor).length !== 0 || 
                            Object.keys(company_insights.inst_data.general_result.worst_factor).length !== 0 ||
                            Object.keys(company_insights.inst_data.general_result.employees_like).length !== 0 ||
                            Object.keys(company_insights.inst_data.general_result.employees_dont_like).length !== 0 ||
                            Object.keys(company_insights.inst_data.general_result.employees_suggestions).length !== 0
                            ) {
                          return <>
                          <h1 className="leading-relaxed mb-1 font-medium">
                          {t("singleProfiles.whatEmployees")} {companys[0].name} {t("singleProfiles.say")}:
                          </h1>                          
                          </>
                        } else {
                        }
                      })()}

{/* Company Insights - Detailed Information about current Company - Comments*/}

                      {(() => {
                        if (Object.keys(company_insights.inst_data.general_result.best_factor).length !== 0) {
                          return <>
                            <details className='ml-4 mt-2 mb-4'>
                                <summary className='cursor-pointer shadow dark:shadow-black rounded-lg p-1 pr-2 w-full flex flex-inline'>
                                <IoHeartOutline size={25} className='inline mr-1 pb-1'/>
                                <div className="leading-relaxed font-medium text-sm">
                                  {t("singleProfiles.employeesLove")}... {Object.getOwnPropertyNames(company_insights.inst_data.general_result.best_factor)[0]}
                                </div>
                              </summary>
                              <div className='font-medium mt-4 text-sm text-gray-500'>
                                {Object.values(company_insights.inst_data.general_result.best_factor)[0].map((comment) => (
                                  <div className='flex w-fit mb-4 p-3 rounded-xl shadow'>
                                    <FaQuoteLeft size={8} className='flex-none mr-2'/>
                                    {comment}
                                    <FaQuoteRight size={8} className='flex-none ml-2'/>
                                  </div>
                                ))}
                              </div>
                            </details>
                          </>
                        } else {
                        }
                      })()}

                      {(() => {
                        if (Object.keys(company_insights.inst_data.general_result.worst_factor).length !== 0) {
                          return <>
                            <details className='ml-4 mt-2 mb-4'>
                                <summary className='cursor-pointer shadow dark:shadow-black rounded-lg p-1 pr-2 w-full flex flex-inline'>
                                <IoHeartDislikeOutline size={25} className='inline mr-1 pb-1'/>
                                <div className="leading-relaxed font-medium text-sm">
                                  {t("singleProfiles.employeesHate")}... {Object.getOwnPropertyNames(company_insights.inst_data.general_result.worst_factor)[0]}
                                </div>
                              </summary>
                              <div className='font-medium mt-4 text-sm text-gray-500'>
                                {Object.values(company_insights.inst_data.general_result.worst_factor)[0].map((comment) => (
                                  <div className='flex w-fit mb-4 p-3 rounded-xl shadow'>
                                    <FaQuoteLeft size={8} className='flex-none mr-2'/>
                                    {comment}
                                    <FaQuoteRight size={8} className='flex-none ml-2'/>
                                  </div>
                                ))}
                              </div>
                            </details>
                          </>
                        } else {
                        }
                      })()}
 
                      {(() => {
                        if (Object.keys(company_insights.inst_data.general_result.employees_like).length !== 0) {
                          return <>
                            <details className='ml-4 mt-2 mb-4'>
                              <summary className='cursor-pointer shadow dark:shadow-black rounded-lg p-1 pr-2 w-full flex flex-inline'>
                                <IoThumbsUpOutline size={25} className='inline mr-1 pb-1'/>
                                <div className="leading-relaxed font-medium text-sm">
                                  {t("singleProfiles.employeesLike")}...
                                </div>
                              </summary>
                              <div className='font-medium mt-4 text-sm text-gray-500'>
                                {company_insights.inst_data.general_result.employees_like.map((comment) => (
                                  <div className='flex w-fit mb-4 p-3 rounded-xl shadow'>
                                    <FaQuoteLeft size={8} className='flex-none mr-2'/>
                                    {comment}
                                    <FaQuoteRight size={8} className='flex-none ml-2'/>
                                  </div>
                                ))}
                              </div>
                            </details>
                          </>
                        } else {
                        }
                      })()}

                      {(() => {
                        if (Object.keys(company_insights.inst_data.general_result.employees_dont_like).length !== 0) {
                          return <>
                            <details className='ml-4 mt-2 mb-4'>
                                <summary className='cursor-pointer shadow dark:shadow-black rounded-lg p-1 pr-2 w-full flex flex-inline'>
                                <IoThumbsDownOutline size={25} className='inline mr-1 pb-1'/>
                                <div className="leading-relaxed font-medium text-sm">
                                  {t("singleProfiles.employeesDislike")}...
                                </div>
                              </summary>
                              <div className='font-medium mt-4 text-sm text-gray-500'>
                                {company_insights.inst_data.general_result.employees_dont_like.map((comment) => (
                                  <div className='flex w-fit mb-4 p-3 rounded-xl shadow'>
                                    <FaQuoteLeft size={8} className='flex-none mr-2'/>
                                    {comment}
                                    <FaQuoteRight size={8} className='flex-none ml-2'/>
                                  </div>
                                ))}
                              </div>
                            </details>
                          </>
                        } else {
                        }
                      })()}
                      
                      {(() => {
                        if (Object.keys(company_insights.inst_data.general_result.employees_suggestions).length !== 0) {
                          return <>
                            <details className='ml-4 mt-2 mb-4'>
                                <summary className='cursor-pointer shadow dark:shadow-black rounded-lg p-1 pr-2 w-full flex flex-inline'>
                                <IoBulbOutline size={25} className='inline mr-1 pb-1'/>
                                <div className="leading-relaxed font-medium text-sm">
                                  {t("singleProfiles.employeesSuggest")}...
                                </div>
                              </summary>
                              <div className='font-medium mt-4 text-sm text-gray-500'>
                                {company_insights.inst_data.general_result.employees_suggestions.map((comment) => (
                                  <div className='flex w-fit mb-4 p-3 rounded-xl shadow'>
                                    <FaQuoteLeft size={8} className='flex-none mr-2'/>
                                    {comment}
                                    <FaQuoteRight size={8} className='flex-none ml-2'/>
                                  </div>
                                ))}
                              </div>
                            </details>
                          </>
                        } else {
                        }
                      })()}

                    </div>
                  </details>
                </>   
              } else {
                return <>
                <div className='flex flex-inline font-medium dark:text-slate-100 mt-2 mb-2'>
                  <div className='self-center pr-0.5'>
                  <IoWarning size={15}/>
                  </div>
                  {(() => {
                    if (companys.length > 0) {
                      return <>
                      <div>
                        {t("singleProfiles.noInformation")} {companys[0].name}
                      </div>
                      </>
                    } else {
                      return <></>
                    }
                  })()} 
                </div>
                </>
              }
            })()} 

{/* Company Insights - Detailed Company History*/}

            <div className='max-w-[300px] font-medium text-sm'>
              {t("singleProfiles.has")} {general_insights.amount_company_worked.amount_company_worked} {t("singleProfiles.workedFor")} {company_insights.ranked.average_popularity}.
            </div>
            <details className='mt-2'>
              <summary className='cursor-pointer w-fit dark:text-slate-100 font-medium'>{t("singleProfiles.companyHistory")}</summary>
              <div className="border-t border-gray-200">
                <div className='mb-8'></div>
                {companysWithRanking.map((c, i) => (
                  <div className="flex relative pb-6">
                  {(() => {
                  if (companysWithRanking.length > Math.abs(i+1)){
                    return <>
                      <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 dark:bg-black pointer-events-none"></div>
                      </div>
                    </>
                  } else {
                  }
                  })()}
                    <div className="flex-shrink-0 shadow w-10 h-10 rounded-full bg-[#03a4aa] inline-flex items-center justify-center dark:text-black text-white relative z-10">
                    {(() => {
                      if (c.ranking) {
                        return <>
                          <div className='mb-0.5 text-2xl'>
                            {c.ranking}
                          </div>
                        </>
                      } else {
                        return <>
                          <div className=''>
                            <RiQuestionMark size={25}/>
                          </div>
                        </>
                      }
                    })()}
                    </div>
                    <div className="flex-grow pl-4">
                      <div className='flex flex-inline'>
                        <IoBriefcaseOutline size={25} className='flex-none mr-1 pb-1'/>
                        <h2 className="font-medium title-font text-sm mb-1 tracking-wide">
                          {c.job_title}<span className='text-slate-500'> - </span> {c.name}
                        </h2>
                      </div>
                      {(() => {
                      if (c.location) {
                        return <>
                          <div className='flex flex-inline'>
                            <IoLocationOutline size={25} className='inline mr-1 pb-1'/>
                            <p className="leading-relaxed mb-1 font-medium text-sm">
                              {c.location}
                            </p>
                          </div>
                        </>
                      } else {
                      }
                    })()}
                      <p className="leading-relaxed font-medium text-sm text-gray-400">
                        <IoTimeOutline size={25} className='inline pb-1'/> {c.time_range}</p>
                    </div>
                  </div>
                ))}
              </div>
            </details>
          </div>

{/* Company Insights - Career Insights*/}
          
          <div className='sm:w-[450px] w-full px-4 py-4 sm:px-6 bg-white dark:bg-gray-900 border-2 border-black shadow dark:shadow-black rounded-xl'>
            <h3 className="leading-6 font-medium text-xl text-black dark:text-white mb-2">{t("singleProfiles.careerInsights")}</h3>
            <div className='font-medium'>
              <div className='text-sm'>
                <div className='flex ml-2'>
                  {t("singleProfiles.duration")}:
                  {(() => {
                    if (time_insights.total_duration.total_year === 0) {
                      return <></>
                    }
                    else if (time_insights.total_duration.total_year > 0){
                      return <>
                      <p className='flex-inline ml-1'> {time_insights.total_duration.total_year} {t("singleProfiles.years")} </p>
                      </>
                    } else {
                      return <>
                      <p className='flex-inline ml-1'> {time_insights.total_duration.total_year} {t("singleProfiles.year")} </p>
                      </>
                    }
                  })()}
                  
                  {(() => {
                    if (time_insights.total_duration.total_month === 0) {
                      return <></>
                    }
                    else if (time_insights.total_duration.total_month > 0){
                      return <>
                      <p className='flex-inline ml-1'>  {time_insights.total_duration.total_month} {t("singleProfiles.months")}</p>
                      </>
                    } else {
                      return <>
                      <p className='flex-inline ml-1'>  {time_insights.total_duration.total_month} {t("singleProfiles.month")}</p>
                      </>
                    }
                  })()}
                </div>
                <div className='flex ml-2'>
                  Ø {t("singleProfiles.durationEmployment")}:
                  {(() => {
                    if (time_insights.average_duration.average_years === 0) {
                      return <></>
                    }
                    else if (time_insights.average_duration.average_years > 0){
                      return <>
                      <p className='flex-inline ml-1'> {time_insights.average_duration.average_years} {t("singleProfiles.years")} </p>
                      </>
                    } else {
                      return <>
                      <p className='flex-inline ml-1'> {time_insights.average_duration.average_years} {t("singleProfiles.year")} </p>
                      </>                  
                    }
                  })()}
                  
                  {(() => {
                    if (time_insights.average_duration.average_months === 0) {
                      return <></>
                    }
                    else if (time_insights.average_duration.average_months > 0){
                      return <>
                      <p className='flex-inline ml-1'> {time_insights.average_duration.average_months} {t("singleProfiles.months")}</p>
                      </>
                    } else {
                      return <>
                      <p className='flex-inline ml-1'> {time_insights.average_duration.average_months} {t("singleProfiles.month")}</p>
                      </>                  
                    }
                  })()}
                </div>
              </div>

{/* Company Insights - Career Insights - Gaps*/}

              <details className='mt-2'>
                <summary className='cursor-pointer font-medium text-black dark:text-white'>{time_insights.gaps.gaps_amount} {t("singleProfiles.gaps")}</summary>
                  <div className="border-t border-gray-200">
                    <div className='mb-4'></div>
                      {time_insights.gaps.duration_gaps.map((gaps) => (
                        <div>
                          <div className='ml-4 mb-4'>
                            {(() => {
                              if (time_insights.gaps.gaps_amount > 0){
                                return <> 
                                  <div className='text-sm'>
                                    <div className='flex flex-inline'>
                                    <IoCodeWorkingOutline size={25} className='pt-1 mr-1'/>
                                    <p className='py-1'>{gaps.from} - {gaps.to}</p>
                                    </div>
                                    <div className='flex flex-inline text-gray-400'>
                                      <IoTimeOutline size={25} className='pb-1'/>
                                      {(() => {
                                      if (gaps.gap_size.years > 0){
                                        return <>
                                        <p className='flex-inline ml-1'> {gaps.gap_size.years} {t("singleProfiles.years")}</p>
                                        </>
                                      } else {
                                      }
                                      })()}
                                      
                                      {(() => {
                                      if (gaps.gap_size.months > 1){
                                        return <>
                                        <p className='flex-inline ml-1'> {gaps.gap_size.months} {t("singleProfiles.months")}</p>
                                        </>
                                      } if (gaps.gap_size.months === 1) {
                                        return <>
                                        <p className='flex-inline ml-1'> {gaps.gap_size.months} {t("singleProfiles.month")}</p>
                                        <div className='has-tooltip'>
                                          <span className='tooltip rounded shadow dark:shadow-black p-1 dark:bg-gray-900 bg-white text-[#03a4aa] -mt-8 w-64'>{t("singleProfiles.reviewNecessary")}</span>
                                          <IoInformationCircleOutline size={15} className="ml-1"/>
                                        </div>
                                        </>
                                      } else {
                                        
                                      } 
                                      })()}
                                    </div>
                                  </div>
                                </>
                              } else {

                              }
                            })()}
                          </div>
                        </div>
                      ))}
                  </div>
              </details>

{/* Company Insights - Career Insights - Short Periods*/}

              {(() => {
              if (time_insights.short_periods.amount_short_periods > 0){
                return <>
                <details className='mt-2'>
                  <summary className='cursor-pointer font-medium text-black dark:text-white'>{time_insights.short_periods.amount_short_periods} {t("singleProfiles.shortterm")}</summary>
                    <div className="border-t border-gray-200">
                      <div className='mb-4'></div>
                        {time_insights.short_periods.companys_short_periods.map((short) => (
                          <div className='ml-4 mb-4'>
                            <div className='text-sm'>
                              <div className='flex flex-inline'>
                                <IoBriefcaseOutline size={25} className='mr-1 pb-1'/>
                                <p className='py-1'>{short.job_title}</p>
                              </div>
                              <div className='flex flex-inline'>
                                <IoTimeOutline size={25} className='mr-1 pb-1'/>
                                <p className='text-gray-400'>{short.time_duration} - {short.time_range}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                </details>
                </>
              } else {

              }
              })()}

{/* Company Insights - Career Insights - Lead positions*/}

              {(() => {
              if (job_insights.lead.amount_lead_positions > 0){
                return <>
                <details className='mt-2'>
                  <summary className='cursor-pointer font-medium text-black dark:text-white'>{job_insights.lead.amount_lead_positions} {t("singleProfiles.leadershipPositions")}</summary>
                    <div className="border-t border-gray-200">
                      <div className='mb-4'></div>
                        {job_insights.lead.lead_positions.map((lead) => (
                          <div className='ml-4 mb-4'>
                            <div className='text-sm'>
                              <div className='flex flex-inline'>
                                <FaUserTie size={25} className='mr-1 pb-1'/>
                                <p className='py-1'>{lead.title}</p>
                              </div>
                              <div className='flex flex-inline'>
                                <IoTimeOutline size={25} className='mr-1 pb-1'/>
                                <p className='text-gray-400'>{lead.duration}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                </details>
                </>
              } else {

              }
              })()}

{/* Company Insights - Career Insights - Relocations*/}

              {(() => {
              if (job_insights.relocations.amount_relocations > 0){
                return <>
                <details className='mt-2'>
                  <summary className='cursor-pointer font-medium text-black dark:text-white'>{t("singleProfiles.relocated")} {job_insights.relocations.amount_relocations} {t("singleProfiles.relocated2")}</summary>
                    <div className="border-t border-gray-200">
                      <div className='mb-4'></div>
                        {job_insights.relocations.relocations.map((rel, i) => (
                          <div className='ml-4 mb-1'>
                            <div className='text-sm'>
                            <div className='flex flex-inline'>
                              <IoLocationOutline size={25} className='flex-none mr-1 pb-1'/>
                              <h2 className="font-medium title-font text-sm mb-1 tracking-wide">
                              {rel.relocation.from.location}<span className='text-gray-500'> - </span> {rel.relocation.from.name}
                              </h2>
                            </div>
                            {(() => {
                            if (job_insights.relocations.relocations.length > Math.abs(i+1)){
                              return <>
                              <div className='flex flex-inline'>
                                <IoArrowUpOutline size={25} className='mr-1 pb-1'/>
                                {/* <p>to</p> */}
                              </div>
                              </>
                            } else {
                              
                            }
                            })()}
                            </div>
                          </div>
                        ))}
                    </div>
                </details>
                </>
              } else {

              }
              })()}

            </div>
          </div>

{/* Company Insights - Education Insights*/}

          <div className='sm:w-[450px] w-full px-4 py-4 sm:px-6 bg-white dark:bg-gray-900 border-2 border-black shadow dark:shadow-black rounded-xl'>
            <h3 className="leading-6 font-medium text-xl text-black dark:text-white mb-2">{t("singleProfiles.educationalInsights")}</h3>
            <div className='flex flex-col justify-center'>
              <div className="flex flex-wrap justify-between">
                <div className='font-medium text-sm'>
                  <p className='pr-1'>
                    {t("singleProfiles.highestEducation")}: {general_insights.highest_education.highest_education}
                  </p>
                  {(() => {
                  if (education_insights.ranked.ranked_institutions.length > 0){
                    return <>
                    <p>
                       {education_insights.ranked.ranked_institutions[0].institution}
                    </p>
                    <p>
                      {t("singleProfiles.rank")}Rang: {education_insights.ranked.average_popularity}
                    </p>
                    </>
                  } else {

                  }
                  })()}
                </div>
              </div>
            </div>

{/* Company Insights - Education Insights - Detailed Education History*/}

            <details className='mt-2'>
              <summary className='cursor-pointer w-fit font-medium text-black dark:text-white'>{t("singleProfiles.educationalHistory")}</summary>
              <div className="border-t border-gray-200">
                <div className='mb-8'></div>
                {educationWithRanking.map((e, i) => (
                  <div className="flex relative pb-6">
                  {(() => {
                  if (educationWithRanking.length > Math.abs(i+1)){
                    return <>
                      <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 dark:bg-black pointer-events-none"></div>
                      </div>
                    </>
                  } else {
                  }
                  })()}
                    <div className="flex-shrink-0 shadow w-10 h-10 rounded-full bg-[#03a4aa] inline-flex items-center justify-center text-white dark:text-black relative z-10">
                    {(() => {
                      if (e.ranking) {
                        return <>
                          <div className='mb-0.5 text-2xl'>
                            {e.ranking}
                          </div>
                        </>
                      } else {
                        return <>
                          <div className='text-white'>
                            <RiQuestionMark size={25}/>
                          </div>
                        </>
                      }
                    })()}
                    </div>
                    <div className="flex-grow pl-4">
                      <div className='flex flex-inline'>
                        <IoSchoolOutline size={25} className='flex-none mr-1 pb-1'/>
                        <h2 className="font-medium title-font text-sm mb-1 tracking-wide">
                        {(() => {
                            if (e.field_of_study) {
                                return <>
                                    {e.field_of_study}<span className='text-gray-500'> - </span>{e.name}
                                </>
                            } else {
                                return <>
                                    {e.name}
                                </>
                            }
                        })()}
                        </h2>
                      </div>
                      <div className='flex flex-inline'>
                      <p className="leading-relaxed mb-1 ml-7 font-medium text-sm">
                          {e.degree}
                      </p>
                      </div>
                      <div className='flex flex-inline'>
                        <IoTimeOutline size={25} className='flex-none mr-1 pb-1'/> 
                        <p className="leading-relaxed font-medium text-sm text-gray-400">
                          {e.time_range}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </details>
          </div>

{/* Company Insights - Skill Insights*/}

          <div className='sm:w-[450px] w-full px-4 py-4 sm:px-6 bg-white dark:bg-gray-900 border-2 border-black shadow dark:shadow-black rounded-xl'>
            <h3 className="leading-6 font-medium text-xl text-black dark:text-white mb-2">{t("singleProfiles.skillInsights")}</h3>
            <div className='mt-4 font-medium'>
              <p className='mb-4 border-b border-gray-200 text-black dark:text-white'>{languages.length} {t("singleProfiles.languages")}</p>
              <div className='flex flex-wrap'>
              {languages.map((l) => (
              <div className="ml-4">
                <div className="flex pr-4">
                  <div className='flex flex-inline'>
                    <h2 className="font-bold title-font text-sm mb-1 pr-1 tracking-wide">
                      {l.language} 
                    </h2>
                  </div>
                  {(() => {
                    if (l.level !== '') {
                      return <>
                        <div>
                          <p className="leading-relaxed mb-1 font-medium text-sm text-gray-500 dark:text-gray-400">
                            - {l.level}
                          </p>
                        </div>
                      </>
                    } else {
                    }
                  })()}
                  {/* <div className='flex flex-inline'>
                    <IoChatbubblesOutline size={25} className='flex-none mr-1 pb-1 text-black'/>
                    <p className="leading-relaxed mb-1 font-medium text-sm text-black">
                      {l.level}
                    </p>
                  </div> */}
                </div>
              </div>
              ))}
              </div>
            </div>

{/* Company Insights - Skill Insights - Detailed Skillset*/}

            <details className='mt-2'>
              <summary className='cursor-pointer w-fit font-medium text-black dark:text-white'>{t("singleProfiles.detailedSkillset")}</summary>
              <div className="border-t border-gray-200">
                <div className='mt-4 flex flex-wrap'>
                  {skills.map((s) => (
                    <div className='shadow-sm dark:shadow-black py-1 px-2 m-1 bg-white dark:bg-gray-900 rounded-xl'>{s.name} </div>
                  ))}
                </div>
              </div>
            </details>
          </div>

{/* Contacting Insights*/}

          <div className='w-full px-4 py-4 sm:px-6 mb-4 bg-white border-2 border-black dark:bg-gray-900 shadow dark:shadow-black rounded-xl'>
            <h3 className="leading-6 font-medium text-xl text-black dark:text-white mb-2">{t("singleProfiles.contactProposals")}</h3>
            <div className='mt-4 font-medium'>
            <p className='mb-4 text-gray-500 dark:text-gray-400'>{t("singleProfiles.aiContact")}</p>
            </div>

{/* Contacting Insights - Contacting suggestions*/}
            <div className=''>
            <details className='mt-2'>
              <summary className='cursor-pointer w-fit font-medium text-black dark:text-white'>{t("singleProfiles.aiSeriousLong")}</summary>
              <div className="border-t border-gray-200">
                <div className='mt-4 flex flex-wrap'>
                  <div className='font-medium mt-4 text-sm text-gray-500'>
                    <div className='flex shadow-sm dark:shadow-black w-fit mb-4 p-3 rounded-xl shadow'>
                      <FaQuoteLeft size={8} className='flex-none mr-2'/>
                      {contacting.serious_txt[0]}
                      <FaQuoteRight size={8} className='flex-none ml-2'/>
                      <button onClick={() =>  {navigator.clipboard.writeText(contacting.serious_txt[0]); copySuccessToast()}} className="text-[#03a4aa]">
                        {t("singleProfiles.copy")}
                      </button>
                    </div>
                    <div className='flex shadow-sm dark:shadow-black w-fit mb-4 p-3 rounded-xl shadow'>
                      <FaQuoteLeft size={8} className='flex-none mr-2'/>
                      {contacting.serious_txt[1]}
                      <FaQuoteRight size={8} className='flex-none ml-2'/>
                      <button onClick={() =>  {navigator.clipboard.writeText(contacting.serious_txt[1]); copySuccessToast()}} className="text-[#03a4aa]">
                        {t("singleProfiles.copy")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </details>

            <details className='mt-2 flex-auto'>
              <summary className='cursor-pointer w-fit font-medium text-black dark:text-white'>{t("singleProfiles.aiSeriousShort")}</summary>
              <div className="border-t border-gray-200">
                <div className='mt-4 flex flex-wrap'>
                  <div className='font-medium mt-4 text-sm text-gray-500'>
                    <div className='flex shadow-sm dark:shadow-black w-fit mb-4 p-3 rounded-xl shadow'>
                      <FaQuoteLeft size={8} className='flex-none mr-2'/>
                      {contacting.short_serious_txt[0]}
                      <FaQuoteRight size={8} className='flex-none ml-2'/>
                      <button onClick={() =>  {navigator.clipboard.writeText(contacting.short_serious_txt[0]); copySuccessToast()}} className="text-[#03a4aa]">
                        {t("singleProfiles.copy")}
                      </button>
                    </div>
                    <div className='flex shadow-sm dark:shadow-black w-fit mb-4 p-3 rounded-xl shadow'>
                      <FaQuoteLeft size={8} className='flex-none mr-2'/>
                      {contacting.short_serious_txt[1]}
                      <FaQuoteRight size={8} className='flex-none ml-2'/>
                      <button onClick={() =>  {navigator.clipboard.writeText(contacting.short_serious_txt[1]); copySuccessToast()}} className="text-[#03a4aa]">
                        {t("singleProfiles.copy")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </details>

            <details className='mt-2 flex-auto'>
              <summary className='cursor-pointer w-fit font-medium text-black dark:text-white'>{t("singleProfiles.aiCasualLong")}</summary>
              <div className="border-t border-gray-200">
                <div className='mt-4 flex flex-wrap'>
                  <div className='font-medium mt-4 text-sm text-gray-500'>
                    <div className='flex shadow-sm dark:shadow-black w-fit mb-4 p-3 rounded-xl shadow'>
                      <FaQuoteLeft size={8} className='flex-none mr-2'/>
                      {contacting.lazy_txt[0]}
                      <FaQuoteRight size={8} className='flex-none ml-2'/>
                      <button onClick={() =>  {navigator.clipboard.writeText(contacting.lazy_txt[0]); copySuccessToast()}} className="text-[#03a4aa]">
                        {t("singleProfiles.copy")}
                      </button>
                    </div>
                    <div className='flex shadow-sm dark:shadow-black w-fit mb-4 p-3 rounded-xl shadow'>
                      <FaQuoteLeft size={8} className='flex-none mr-2'/>
                      {contacting.lazy_txt[1]}
                      <FaQuoteRight size={8} className='flex-none ml-2'/>
                      <button onClick={() =>  {navigator.clipboard.writeText(contacting.lazy_txt[1]); copySuccessToast()}} className="text-[#03a4aa]">
                        {t("singleProfiles.copy")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </details>

            <details className='mt-2 flex-auto'>
              <summary className='cursor-pointer w-fit font-medium text-black dark:text-white'>{t("singleProfiles.aiCasualShort")}</summary>
              <div className="border-t border-gray-200">
                <div className='mt-4 flex flex-wrap'>
                  <div className='font-medium mt-4 text-sm text-gray-500'>
                    <div className='flex shadow-sm dark:shadow-black w-fit mb-4 p-3 rounded-xl shadow'>
                      <FaQuoteLeft size={8} className='flex-none mr-2'/>
                      {contacting.short_lazy_txt[0]}
                      <FaQuoteRight size={8} className='flex-none ml-2'/>
                      <button onClick={() =>  {navigator.clipboard.writeText(contacting.short_lazy_txt[0]); copySuccessToast()}} className="text-[#03a4aa]">
                        {t("singleProfiles.copy")}
                      </button>
                    </div>
                    <div className='flex shadow-sm dark:shadow-black w-fit mb-4 p-3 rounded-xl shadow'>
                      <FaQuoteLeft size={8} className='flex-none mr-2'/>
                      {contacting.short_lazy_txt[1]}
                      <FaQuoteRight size={8} className='flex-none ml-2'/>
                      <button onClick={() =>  {
                        navigator.clipboard.writeText(contacting.short_lazy_txt[1]); copySuccessToast()}} className="text-[#03a4aa]">
                        {t("singleProfiles.copy")}    
                      </button>
                      <Toaster />
                    </div>
                  </div>
                </div>
              </div>
            </details>
            </div>
          </div>

        </div>
        </div>
    </div>
  </div>
  )
}

export default SingleProfile