import React from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { 
    IoFilter
  } from "react-icons/io5";

function ResultFilter(props) {
    const navigate = useNavigate();
    const filterData = JSON.parse(localStorage.getItem("filter_data"))
    const [loading, setLoading] = useState(false);
    const handleLoading = () => {
      setLoading(!loading);
    };

    const closeDetails = () => {
      document.getElementById("details").removeAttribute("open");
      // window.location = "#details";
  }

    const [data, setData] = useState({
        min_work_exp: filterData.min_work_exp || "",
        max_work_exp: filterData.max_work_exp || "",
        highest_education: filterData.highest_education || "",
        min_company_reputation: filterData.min_company_reputation || "",
        max_company_reputation: filterData.max_company_reputation || "",
        min_university_reputation: filterData.min_university_reputation || "",
        max_university_reputation: filterData.max_university_reputation || "",
        location: filterData.location || "",
        language: filterData.language || "",
        net_min_work_exp: filterData.net_min_work_exp || "",
        net_max_work_exp: filterData.net_max_work_exp || "",
        min_lead_duration: filterData.min_lead_duration || "",
        max_lead_duration: filterData.max_lead_duration || "",
        job_title: filterData.job_title || "",
        skills: String(filterData.skills) || []
      })

      // console.log(data)

      // const handleChange = (e) => {
      //   const value = e.target.value;
      //   setData({
      //     ...data,
      //     [e.target.name]: value
      //   });
      // };

      // const handleClick = () => {
      //   setData({     
      //       min_work_exp: "",
      //       max_work_exp: null,
      //       highest_education: "",
      //       min_company_reputation: "",
      //       max_company_reputation: "",
      //       min_university_reputation: "",
      //       max_university_reputation: "",
      //       location: "",
      //       language: "",
      //       net_min_work_exp: null,
      //       net_max_work_exp: null,
      //       min_lead_duration: null,
      //       max_lead_duration: null,
      //       job_title: "",
      //       skills: []
      //    })
      // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const userData = {
            min_work_exp: parseInt(data.min_work_exp),
            max_work_exp: parseInt(data.max_work_exp),
            highest_education: data.highest_education,
            min_company_reputation: data.min_company_reputation,
            max_company_reputation: data.max_company_reputation,
            min_university_reputation: data.min_university_reputation,
            max_university_reputation: data.max_university_reputation,
            location: data.location,
            language: data.language,
            net_min_work_exp: parseInt(data.net_min_work_exp),
            net_max_work_exp: parseInt(data.net_max_work_exp),
            min_lead_duration: parseInt(data.min_lead_duration),
            max_lead_duration: parseInt(data.max_lead_duration),
            job_title: data.job_title,
            skills: data.skills?.split(",")
        };
        console.log(userData);
    
        await axios({
            method:"post",
            url: "https://sc8oqenus2.execute-api.eu-central-1.amazonaws.com/test/match-filter", 
            data: {filter_parameters: userData},
            headers: { "Content-Type": "application/json", "Authorization": localStorage.getItem('faindsToken')},
        })
        .then((response) => {
        navigate("/results", {state:response.data});
          localStorage.setItem("filter_data", JSON.stringify(userData))
          console.log(response);
          handleLoading();
          closeDetails(); 
          props.callhandlefilter()
        })
        .catch((error) => {
          handleLoading()
          closeDetails()
          props.callhandlefilter()
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
      };
    

  return (
    <div>
    <datalist id="education_list">
        <option value="Bachelor"/>
        <option value="Master"/>
        <option value="Doctor"/>
    </datalist>
    <datalist id='ranking_list'>
        <option value="A"/>
        <option value="B"/>
        <option value="C"/>
        <option value="D"/>
        <option value="E"/>
        <option value="F"/>
        <option value="G"/>
        <option value="H"/>
    </datalist>

    {!loading ? 
        <div className='absolute flex flex-col items-center justify-center h-full w-full bg-black opacity-80 z-[-10]'> 
          <div className="h-3 relative w-[50%] rounded-full overflow-hidden">
            <div className="w-full h-full bg-gray-200 absolute"></div>
            <div id="bar" className="h-full bg-gradient-to-r from-teal-500 to-cyan-500 absolute w-0"></div>
          </div>
        </div> : 
        <div className='absolute flex flex-col items-center justify-center h-full w-full bg-black opacity-80 z-30'> 
          <div className="h-3 relative w-[50%] rounded-full overflow-hidden">
            <div className="w-full h-full bg-gray-200 absolute"></div>
            <div id="bar" className="delay-150 ease-in-out duration-[10000ms] h-full bg-gradient-to-r from-teal-500 to-cyan-500 absolute w-full"></div>
          </div>
          <p className='text-white text-center mt-4'>Suche mit neuen Parametern nach passenden Kandidaten...</p>
        </div>
      }

    <div id="details" className=' bg-slate-50 dark:bg-black'>
        <div className='cursor-pointer w-fit text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500 font-medium justify-center mx-auto mb-2 w-full flex flex-inline'>
        {/* <IoFilter size={25} className='inline mr-2'/> */}
        <div className='text-xl uppercase font-bold'>Suchfilter</div>
        </div>
        {/* <p className='text-red-300 text-center mx-auto'>Suchfilter sind noch experiementiell und könnten mit Fehlern einhergehen!</p> */}
        <div className='ml-auto mr-auto content-center max-w-[1240px]'>
          <div className=" md:grid md:grid-cols-2 md:gap-6">
            <div className="mt-5 content-center md:mt-0 md:col-span-2 ">
              <form onSubmit={handleSubmit}>
                  <div className="px-4 sm:p-6">
                    <div className="grid grid-cols-6 gap-2 sm:gap-6">
                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="min_work_exp" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Arbeitserfahrung min.
                        </label>
                        <input
                          type="number"
                          name="min_work_exp"
                          id="min_work_exp"
                          min={0}
                          max={100}
                          placeholder="0 - 100 (Angabe in Jahren)"
                          value={data.min_work_exp}
                          onChange={(event) => setData({...data, min_work_exp: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="max_work_exp" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Arbeitserfahrung max
                        </label>
                        <input
                          type="number"
                          name="max_work_exp"
                          id="max_work_exp"
                          placeholder="0 - 100 (Angabe in Jahren)"
                          min={0}
                          max={100}
                          value={data.max_work_exp}
                          onChange={(event) => setData({...data, max_work_exp: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="highest_education" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Höchster Abschluss
                        </label>
                        <input
                          type="text"
                          name="highest_education"
                          id="highest_education"
                          list='education_list'
                          placeholder="Bachelor - Master - Doktor"
                          value={data.highest_education}
                          onChange={(event) => setData({...data, highest_education: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="min_company_reputation" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Firmenreputation min.
                        </label>
                        <input
                          type="text"
                          name="min_company_reputation"
                          id="min_company_reputation"
                          list='ranking_list'
                          placeholder="Min. Ranking: A - H"
                          value={data.min_company_reputation}
                          onChange={(event) => setData({...data, min_company_reputation: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="max_company_reputation" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Firmenreputation max.
                        </label>
                        <input
                          type="text"
                          name="max_company_reputation"
                          id="max_company_reputation"
                          list='ranking_list'
                          placeholder="Max. Ranking: A - H"
                          value={data.max_company_reputation}
                          onChange={(event) => setData({...data, max_company_reputation: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="location" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Ort
                        </label>
                        <input
                          type="text"
                          name="location"
                          id="location"
                          placeholder="Wo ist die gesuchte Stelle?"
                          value={data.location}
                          onChange={(event) => setData({...data, location: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="min_university_reputation" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Unireputation min.
                        </label>
                        <input
                          type="text"
                          name="min_university_reputation"
                          id="min_university_reputation"
                          list='ranking_list'
                          placeholder="Min. Ranking: A - H"
                          value={data.min_university_reputation}
                          onChange={(event) => setData({...data, min_university_reputation: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="max_university_reputation" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Unireputation max.
                        </label>
                        <input
                          type="text"
                          name="max_university_reputation"
                          id="max_university_reputation"
                          list='ranking_list'
                          placeholder="Max. Ranking: A - H"
                          value={data.max_university_reputation}
                          onChange={(event) => setData({...data, max_university_reputation: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="language" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Sprachen
                        </label>
                        <input
                          type="text"
                          name="language"
                          id="language"
                          placeholder="Welche Sprache sollte er/sie sprechen?"
                          value={data.language}
                          onChange={(event) => setData({...data, language: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      {/* <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="net_min_work_exp" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          experience
                        </label>
                        <input
                          type="text"
                          name="net_min_work_exp"
                          id="net_min_work_exp"
                          placeholder="what is your first name?"
                          value={data.net_min_work_exp}
                          onChange={handleChange}
                          className="mt-1 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="net_max_work_exp" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Max. net. work experience
                        </label>
                        <input
                          type="text"
                          name="net_max_work_exp"
                          id="net_max_work_exp"
                          placeholder="what is your first name?"
                          value={data.net_max_work_exp}
                          onChange={handleChange}
                          className="mt-1 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div> */}

                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="min_lead_duration" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Führungserf. min.
                        </label>
                        <input
                          type="number"
                          name="min_lead_duration"
                          id="min_lead_duration"
                          placeholder="0 - 100 (Angabe in Jahren)"
                          value={data.min_lead_duration}
                          onChange={(event) => setData({...data, min_lead_duration: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="max_lead_duration" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Führungserf. max.
                        </label>
                        <input
                          type="number"
                          name="max_lead_duration"
                          id="max_lead_duration"
                          placeholder="0 - 100 (Angabe in Jahren)"
                          value={data.max_lead_duration}
                          onChange={(event) => setData({...data, max_lead_duration: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3 md:col-span-2 sm:col-span-2">
                        <label htmlFor="job_title" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Jobtitel
                        </label>
                        <input
                          type="text"
                          name="job_title"
                          id="job_title"
                          placeholder="Wie heißt die zu besetzende Stelle?"
                          value={data.job_title}
                          onChange={(event) => setData({...data, job_title: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-6">
                        <label htmlFor="skills" className="block text-sm font-medium text-slate-900 dark:text-slate-100 dark:text-slate-300">
                          Skills
                        </label>
                        <input
                          type="text"
                          name="skills"
                          id="skills"
                          placeholder="Welche Skills sollte der Kanidat mitbringen?"
                          value={data.skills}
                          onChange={(event) => setData({...data, skills: event.target.value})}
                          className="mt-1 border-2 bg-slate-200 border-slate-400 hover:border-slate-600 dark:bg-slate-800 dark:border-slate-600 dark:hover:border-slate-400 text-slate-900 dark:text-slate-100 focus:ring-[#6EA8D8] focus:border-[#6EA8D8] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                    </div>
                  </div>
                  <div className="px-4 bg-gray-50 dark:bg-black text-right sm:px-6">
                    <button
                      onClick ={() => handleLoading()}
                      type="submit"
                      className='bg-gradient-to-r from-teal-500 to-cyan-500 hover:bg-[#1b6099] w-[200px] justify-center flex rounded-md font-medium my-6 mx-auto py-3 text-black'>
                      Anwenden
                    </button>
                  </div>
              </form>
            </div>
          </div>
        </div>
    </div>
    </div>
  )
}

export default ResultFilter