import React, { useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import {
  FaExternalLinkSquareAlt,
  FaLinkedin,
  FaTwitterSquare,
} from 'react-icons/fa';
import { 
  IoSchoolOutline,
  IoBriefcaseOutline
} from "react-icons/io5";
import { VscFileSymlinkFile } from "react-icons/vsc";


function ProfileOneHome() {
  const { t } = useTranslation();

  return (
        <div className='flex justify-center mx-auto px-2'>
          {/* Candidate Preview Section */}
          <div className="flex flex-wrap max-w-[400px] lg:max-w-[490px] justify-between px-4 py-5 sm:px-6 text-slate-900 dark:text-slate-200 bg-white dark:bg-gray-900 shadow rounded-xl my-4">
            <div className='flex max-w-[400px]'>
              <div className='shrink w-[300px] mb-2'>
                <h3 className="text-2xl leading-6 font-medium ">Jack Sparrow</h3>
                    <h3 className="text-lg leading-6 font-medium">Data Scientist</h3>
                <div className='font-medium dark:text-slate-400 text-slate-500 text-sm'>
                  <p>Bochum, North Rhine-Westphalia, Germany</p>
                </div>
              </div>
              <div className='ml-4 w-[25%]'>
                <CircularProgressbar styles={{ text: {fill: "#03a4aa"}, path: { stroke:"#03a4aa"}, trail: { stroke:"transparent"}}} strokeWidth="12" value={100} text={`100%`} />
              </div>
            </div>

              <div className='w-full my-2 font-medium text-slate-700 dark:text-slate-300 text-sm'>
                <div className="flex-shrink-0 flex">
                  <div className='self-center'>
                    <IoBriefcaseOutline size={25} className='inline mr-1 pb-1 text-black dark:text-white'/>
                  </div>
                  <div className='flex flex-col border-l dark:border-white border-black'>
                        <p className='flex-none ml-2'>
                          {t("profilesHome.company")}: Bayer
                        </p>

                        <p className='flex-none ml-2'>
                        {t("profilesHome.rank")}: A
                        </p>

                    <p className='flex-none ml-2 mt-2'>
                      {t("profilesHome.total")}: 5 Firmen
                    </p>
                    <p className='flex-none ml-2'>
                      Ø {t("profilesHome.rank")}: C 
                    </p>
                    <div className='flex ml-2'>
                      {t("profilesHome.duration")}:
                        <p className='flex-inline ml-1'> 10 {t("profilesHome.years")}</p>

                        <p className='flex-inline ml-1'> 10 {t("profilesHome.months")}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='w-full my-2 text-slate-700 dark:text-slate-300 font-medium text-sm'>
                  <div className="flex-shrink-0 flex">
                    <div className='self-center'>
                      <IoSchoolOutline size={25} className='inline mr-1 pb-1 text-black dark:text-white'/>
                    </div>
                    <div className='flex flex-col border-l dark:border-white border-black'>
                      <p className='flex-none ml-2'>
                      Ruhr-Universitat Bochum
                      </p>
                      <p className='flex-none ml-2'>
                        {t("profilesHome.education")}: Doctor
                      </p>
                      <p className='flex-none ml-2'>
                        {t("profilesHome.rank")}: C
                      </p>
                    </div>
                  </div>
              </div>

            <div className='w-[300px] flex flex-row my-6'>

                  <button 
                    className='flex mr-2 shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium w-fit'>
                    <FaLinkedin size={25} />
                    <div className='px-2'>LinkedIn</div>
                  </button>

                        <button 
                          className='flex mr-2 shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium w-fit'>
                          <FaTwitterSquare size={25} />
                          <div className='px-2'>Twitter</div>
                        </button>

                        <button 
                          className='flex shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium w-fit'>
                          <FaExternalLinkSquareAlt size={25}/>
                          <div className='px-2'>Portfolio</div>
                        </button>

              
            </div>
            <div className='flex flex-inline items-center mx-auto'>
              <button
                className='w-full inline right-0 mb-1 mr-1 font-medium text-white dark:text-black shadow shadow-[#03a4aa] px-2 py-1 rounded-md bg-gradient-to-r from-teal-500 to-cyan-500 hover:bg-[#1b6099]'>
                <VscFileSymlinkFile size={20} className='mr-1 inline'/>
                {t("profilesHome.to")} Jack Sparrow's {t("profilesHome.profile")}
              </button>
            </div>
          </div>
        </div>
  )
}

export default ProfileOneHome