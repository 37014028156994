import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='dark:bg-black bg-slate-50 w-full mx-auto py-4 px-4 lg:col-span-3 gap-8 dark:text-slate-100 text-slate-900'>
      <div className='flex justify-between justify-center items-center md:w-[75%] my-6'>
        <h1 className='text-3xl font-bold'>f<span className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500'>AI</span>nds</h1>
          <h6 className='pt-2 px-4 font-medium sm:text-base text-sm hover:text-slate-500'><Link to="/impressum">Legal Disclosure</Link></h6>
          <h6 className='pt-2 px-4 font-medium sm:text-base text-sm hover:text-slate-500'><Link to="/privacy">Privacy Policy</Link></h6>
      </div>
    </div>
  );
};

export default Footer;
