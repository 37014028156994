import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  FaExternalLinkSquareAlt,
  FaLinkedin,
  FaTwitterSquare,
} from 'react-icons/fa';
import { 
  IoSchoolOutline,
  IoBriefcaseOutline
} from "react-icons/io5";
import { VscFileSymlinkFile } from "react-icons/vsc";


function Profile({onSubmit, id, name, location, current_industry, distance, companys, career, education, skills, languages, score, general_insights, time_insights, company_insights, education_insights, job_insights, link, twitter, personal_website, contacting }) {
  const [profile] = useState({id, name, location, current_industry, distance, companys, career, education, skills, languages, score, general_insights, time_insights, company_insights, education_insights, job_insights, link, twitter, personal_website, contacting});
  // const educationWithRanking = education.map((education) => {
  //    // here we define a new object that is the same as your object that is currently looped up to in your arrNames array
  //   const newEducation = education;
  //   // loop your second array over this currently looped to object, seeing if the name matches
  //   education_insights.ranked.ranked_institutions.forEach((education_insights) => {
  //     if (education.name === education_insights.institution) {
  //       newEducation.ranking = education_insights.ranking; // if they do set a new property for your new object called info as the info from item 2 of this arrInfo array
  //     }
  //   });
    
  //   return newEducation
  // });

  const companysWithRanking = companys.map((companys) => {
    const newCompany = companys; // here we define a new object that is the same as your object that is currently looped up to in your arrNames array
    // loop your second array over this currently looped to object, seeing if the name matches
    company_insights.ranked.ranked_institutions.forEach((company_insights) => {
      if (companys.name === company_insights.institution) {
        newCompany.ranking = company_insights.ranking; // if they do set a new property for your new object called info as the info from item 2 of this arrInfo array
      }
    });

    return newCompany
  });

  const { t } = useTranslation();

  return (
  <div key={id}>
    <div className=''>
      <div className=''>
        <div className='flex flex-col justify-center mx-auto px-2'>
          {/* Candidate Preview Section */}
          <div className="flex flex-wrap max-w-[400px] lg:max-w-[490px] justify-between px-4 py-5 sm:px-6 text-slate-900 dark:text-slate-200 bg-white dark:bg-gray-900 shadow rounded-xl my-4">
            <div className='flex max-w-[400px]'>
              <div className='shrink w-[300px] mb-2'>
                <h3 className="text-2xl leading-6 font-medium ">{name}</h3>
                {(() => {
                  if (companys.length > 0) {
                    return <>
                    <h3 className="text-lg leading-6 font-medium">{companys[0].job_title}</h3>
                    </>
                  } else {
                    return <></>
                  }
                })()} 
                {/* <h3 className="text-lg leading-6 font-medium">{companys[0].job_title}</h3> */}
                <div className='font-medium dark:text-slate-400 text-slate-500 text-sm'>
                  <p>{location}</p>
                  {(() => {
                    if (distance) {
                      return <>
                      <p>{t("profiles.distance")}: {distance} km</p>
                      </>
                  } else {
                    return <>
                    <p className='text-transparent'>.</p>
                    </>
                      }
                  })()} 
                </div>
              </div>

              {(() => {
                  if (score > 0) {
                    return <>
                      <div className='ml-4 w-[25%] has-tooltip'>
                        <CircularProgressbar styles={{ text: {fill: "#03a4aa"}, path: { stroke:"#03a4aa"}, trail: { stroke:"transparent"}}} strokeWidth="12" value={score} text={`${Math.round(score)}%`} />
                        <span className='tooltip rounded shadow dark:shadow-black p-1 dark:bg-gray-900 bg-white text-[#03a4aa] -mt-8'>{t("profiles.score")}</span>

                      </div>
                    </>
                  } else {
                    return <></>
                  }
                })()}
            </div>

              <div className='w-full my-2 font-medium text-slate-700 dark:text-slate-300 text-sm'>
                <div className="flex-shrink-0 flex">
                  <div className='self-center'>
                    <IoBriefcaseOutline size={25} className='inline mr-1 pb-1 text-black dark:text-white'/>
                  </div>
                  <div className='flex flex-col border-l dark:border-white border-black'>
                    {(() => {
                      if (companys.length > 0) {
                        return <>
                        <p className='flex-none ml-2'>
                        {t("profiles.company")}: {companys[0].name}
                        </p>
                        </>
                      } else {
                        return <></>
                      }
                    })()}

                    {(() => {
                      if (companysWithRanking.length > 0){
                        return <>
                          <p className='flex-none ml-2'>
                          {t("profiles.rank")}: {companysWithRanking[0].ranking}
                          </p>
                        </>
                      } else {
                      }
                    })()}
                    <p className='flex-none ml-2 mt-2'>
                    {t("profiles.total")}: {general_insights.amount_company_worked.amount_company_worked} {t("profiles.companies")} 
                    </p>
                    <p className='flex-none ml-2'>
                    Ø {t("profiles.rank")}: {company_insights.ranked.average_popularity} 
                    </p>
                    <div className='flex ml-2'>
                    {t("profiles.duration")}:
                      {(() => {
                      if (time_insights.total_duration.total_year > 0){
                        return <>
                        <p className='flex-inline ml-1'> {time_insights.total_duration.total_year} {t("profiles.years")}</p>
                        </>
                      } else {
                      }
                      })()}
                      
                      {(() => {
                      if (time_insights.total_duration.total_month > 0){
                        return <>
                        <p className='flex-inline ml-1'>  {time_insights.total_duration.total_month} {t("profiles.months")}</p>
                        </>
                      } else {
                      }
                      })()}
                    </div>
                  </div>
                </div>
              </div>

              <div className='w-full my-2 text-slate-700 dark:text-slate-300 font-medium text-sm'>
              {(() => {
                if (education_insights.ranked.ranked_institutions.length > 0){
                  return <>
                  <div className="flex-shrink-0 flex">
                    <div className='self-center'>
                      <IoSchoolOutline size={25} className='inline mr-1 pb-1 text-black dark:text-white'/>
                    </div>
                    <div className='flex flex-col border-l dark:border-white border-black'>
                      <p className='flex-none ml-2'>
                        {education_insights.ranked.ranked_institutions[0].institution}
                      </p>
                      <p className='flex-none ml-2'>
                      {t("profiles.education")}: {general_insights.highest_education.highest_education}
                      </p>
                      <p className='flex-none ml-2'>
                      {t("profiles.rank")}: {education_insights.ranked.average_popularity}
                      </p>
                    </div>
                  </div>
                  </>
                } else {
                }
                })()}
              </div>

            <div className='w-[300px] flex flex-row my-6'>
              <>
                <form>
                  <button 
                    type='submit'
                    formAction={link} 
                    formTarget="_blank"
                    className='flex mr-2 shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium w-fit'>
                    <FaLinkedin size={25} />
                    <div className='px-2'>LinkedIn</div>
                  </button>
                </form>
              </>
                {/* Function to check if there is a Twitter Link attached to the Profile */}
                {(() => {
                  if (twitter.length > 1) {
                    return <>
                      <form>
                        <button 
                          type="submit"
                          formAction={twitter}
                          formTarget="_blank"
                          className='flex mr-2 shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium w-fit'>
                          <FaTwitterSquare size={25} />
                          <div className='px-2'>Twitter</div>
                        </button>
                      </form>
                    </>
                  } else {
                  }
                })()}
                {/* Function to check if there is a Personal Website Link attached to the Profile */}
                {(() => {
                  if (personal_website.length > 1) {
                    return <>
                      <form>
                        <button 
                          type='submit'
                          formAction={personal_website} 
                          formTarget="_blank"
                          className='flex shadow shadow-[#03a4aa] bg-[#03a4aa] hover:bg-[#026e72] text-slate-100 dark:text-slate-900 rounded font-medium w-fit'>
                          <FaExternalLinkSquareAlt size={25}/>
                          <div className='px-2'>Portfolio</div>
                        </button>
                      </form>
                    </ >   
                  } else {
                  }
                })()}              
            </div>
            {/* </div> */}
            <div className='flex flex-inline items-center mx-auto'>
              <button
                onClick={() => onSubmit(profile)}
                className='w-full inline right-0 mb-1 mr-1 font-medium text-white dark:text-black shadow shadow-[#03a4aa] px-2 py-1 rounded-md bg-gradient-to-r from-teal-500 to-cyan-500 hover:bg-[#1b6099]'>
                <VscFileSymlinkFile size={20} className='mr-1 inline'/>
                {t("profiles.to")} {name}'s {t("profiles.profile")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Profile