import React from 'react'
import { useTranslation } from 'react-i18next';

function ResultHeader(props) {

  const { t } = useTranslation();

  return (
    <div className='max-w-[800px] w-auto sm:justify-center sm:pt-0 justify-start mx-auto text-center flex flex-col relative pb-4'>
      <p className='text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-500 text-lg uppercase font-bold p-2'>
         {props.result_count} {t("resultHeader.countResult")}
      </p>
      {(() => {
        if (props.result_count === 0){
          return <>
              <p> <span className='text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-rose-500 font-bold'> {t("resultHeader.noResults")} </span> 😢 </p>
          </>
        } else {
        }
      })()}
    </div>
  )
}

export default ResultHeader